/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from 'react-router-dom'
import './home.scss'
import DashboardLayout from "./dashBoardLayout";
import { useSelector, useDispatch } from 'react-redux';
import { loadMasterData, setBreadcrumbs, getActivityTypeDetailsCountsByUserId, getActivityTypeDetailsCountsByUserIdRBH } from '../../../redux/actions/common';
import { addUpdateLastMasterSyncTime, resetMasterDataSyncTime } from '../../../offlineManager/leadOfflineManager';
import { isMasterSyncRequired, validateUserOfflineSession } from '../../../offlineManager/userAuthOfflineManager';
import {
  getAutoCompleteActivities, getBDMforABHlist, getTeamlist, getSecondLevelSubordinatelist, getSecondLevelTeamlist, getThirdLevelSubordinatelist, getThirdLevelTeamlist,
  logoutUserReducer, getABHByZBH, getFourthLevelSubordinatelist, getFourthLevelTeamlist, getLeadStatusList
} from "../../../redux-reducer/reducer";
import ModalPopup from 'f1-modal-react';
import { Button } from "@material-ui/core";
import DashboardMyTeamLayout from './dashBoardMyTeamLayout';
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { setMenuDisabled } from "../../../redux-reducer/reducer/leadListReducer";
import DashboardNHRBHZBHMyTeamLayout from "./dashBoardNHRBHZBHMyTeamLayout";
import { getUniqueDeviceId } from "../../../utils/utils";
import {
  getPartnerDetails,
} from "../../../redux/actions";
import PercentageBar from "../../../percentageBar";
import CheckDeviceType from "../../../utils/checkDeviceType";


const Home = () => {

  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const history = useHistory();
  const commonState = useSelector(state => state);
  const isSettingUpActivate = commonState.commonReducer.isSettingUpActivate
  const userState = commonState.userReducer;
  const bdmFormState = commonState.leadFormReducer.BDMList;
  const ABHListForZBH = commonState.leadFormReducer.ABHListForZBH;
  
  const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList;
  const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList;
  const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList;

  const [UserId] = useState(userState.profileInfo.userId);
  const [RoleId] = useState(userState.profileInfo.roleId);
  const [channelid] = useState(userState.profileInfo.channelid);

  const [showModal, setShowModal] = useState(false);
  const [showModalSetup, setShowModalSetup] = useState(false);
  const [modalLoactionAccess, setmodalLoactionAccess] = useState(false);

  const [showMyView, setShowMyView] = useState(true);
  const [showTeamView, setShowTeamView] = useState(false);
  const [defaultSelectedBDM, setSelectedBDM] = useState(0);
  const [bdmList, setBdmList] = useState([]);
  const [selectedView, setSelectedView] = useState(RoleId)
  const [rbhView, setRbhView] = useState(true)

  //Second Leval subordinates

  const [selectedSecondLevelSubordinate, setSelectedSecondLevelSubordinate] = useState(null);
  const [selectedThirdLevelSubordinate, setSelectedThirdLevelSubordinate] = useState(null);
  const [selectedFourthLevelSubordinate, setSelectedFourthLevelSubordinate] = useState(null);


  const [selectedSecondLevelSubordinateList, setSelectedSecondLevelSubordinateList] = useState([]);
  const [selectedThirdLevelSubordinateList, setSelectedThirdLevelSubordinateList] = useState([]);
  const [selectedFourthLevelSubordinateList, setSelectedFourthLevelSubordinateList] = useState([]);

  const [showSecondDropdown, setshowSecondDropdown] = useState(false)
  const [showThirdDropdown, setshowThirdDropdown] = useState(false)
  const [showFourthDropdown, setshowFourthDropdown] = useState(false)


  const breadcrumbs = [
    { name: 'Home', url: '/home' }
  ];
  const { getLoginCount } = commonState.leadListReducer;
  const onMyViewClick = (param) => {
    if (param === "myView") {
      setShowMyView(true)
      setShowTeamView(false)
      setSelectedBDM(0)
      dispatch({
        type: "viewFor",
        payload: "SELF"
      })
      dispatch({
        type: "SetBDMByABH",
        payload: 'all'
      })
      if (RoleId === "NH" || RoleId === "RBH" || RoleId === "ZBH") {
        setSelectedView(RoleId)
        if (channelid === 21 && RoleId === "RBH") {
          if ((RoleId === "RBH" || RoleId === "ABH") && channelid === 21) {
            const data = {
              userId: UserId,
              roleName: RoleId,
              viewFor: "SELF",
              subordinateUserIds: "all"
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
          }
        }
      } else {
        setSelectedView('ABH')
      }
      setshowSecondDropdown(false);
      setshowThirdDropdown(false);
      setshowFourthDropdown(false);

    } else {
      setShowMyView(false)
      setShowTeamView(true)
      if (RoleId === "NH" || RoleId === "RBH" || RoleId === "ZBH") {
        if (channelid === 2) {
          dispatch({
            type: "viewFor",
            payload: RoleId === "ZBH" ? "ABH" : RoleId === "NH" ? "ZBH" : "SELF"
          })
          setSelectedView(RoleId === "ZBH" ? "ABH" : RoleId === "NH" ? "ZBH" : "SELF")
        } else {
          dispatch({
            type: "viewFor",
            payload: bdmList[0]?.Level
          })
          setSelectedView(bdmList[0]?.Level)
        }
        setSelectedSecondLevelSubordinate(null)
        setSelectedThirdLevelSubordinate(null)
        setSelectedFourthLevelSubordinate(null)
        if (RoleId === "RBH" || RoleId === "ZBH" && channelid === 1) {
          const data = {
            userId: UserId,
            roleName: RoleId,
            viewFor: RoleId === "ZBH" ? "RBH" : "ABH",
            subordinateUserIds: "ALL"
          }
          dispatch(getActivityTypeDetailsCountsByUserId(data))
          dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
       }
      } else {
        dispatch({
          type: "viewFor",
          payload: "BDM"
        })
        setSelectedView('BDM')

        dispatch({
          type: "SetBDMByABH",
          payload: 'all'
        })
      }
    }
  }
  useEffect(() => {
    console.log('Unique device ID ', getUniqueDeviceId());

    if (isOnline) {
      // getMasterData();
    }

    if (RoleId !== "BDM" && channelid == 1) {
    //  const data = {
    //     userId: UserId,
    //     roleName: "bdm",
    //     viewFor: "self",
    //     subordinateUserIds: "all"
    //   }
    //Added code for RBH sales management
      let data;
      if(RoleId == "RBH" && channelid == 1){
       data= {
          userId: UserId,
          roleName: "ABH",
          viewFor: "self",
          subordinateUserIds: "all"
        }
        dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
      }else{
         data = {
          userId: UserId,
          roleName: "bdm",
          viewFor: "self",
          subordinateUserIds: "all"
        }
      }
    
      dispatch(getActivityTypeDetailsCountsByUserId(data))
    }
    dispatch(setBreadcrumbs(breadcrumbs))
    dispatch({
      type: "viewFor",
      payload: "SELF"
    })
    dispatch({
      type: "SetBDMByABH",
      payload: 'all'
    })
    setTimeout(() => {
      validateUserOfflineSession().then((isValid) => {
        setShowModal(!isValid);
      });
    }, 3000);
  }, []);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((success) => {
      console.log('location permission is given...');
      setmodalLoactionAccess(false);
    }, (error) => {
      console.log('location permission is not given...');
      setmodalLoactionAccess(true);
    });
  }, [modalLoactionAccess]);//modalLoactionAccess

  const handleClose = () => {
    setmodalLoactionAccess(false);
  };

  useEffect(() => {
    if (isOnline) {
      getBDMList();
      if (channelid === 2 && (RoleId === "ZBH")) {
        dispatch(getABHByZBH(UserId, "ABH"));
      }
    }
  }, [showTeamView, selectedView, RoleId, channelid, UserId, isOnline]);


  useEffect(() => {
    if (bdmFormState && bdmFormState.UsersDetails && bdmFormState.UsersDetails.length > 0) {
      setBdmList(bdmFormState.UsersDetails);
    }
  }, [bdmFormState])
  useEffect(() => {
    if (secondLevelSubordinateList && secondLevelSubordinateList.UsersDetails && secondLevelSubordinateList.UsersDetails.length > 0) {
      setSelectedSecondLevelSubordinateList(secondLevelSubordinateList.UsersDetails);
    }
    if (thirdLevelSubordinateList && thirdLevelSubordinateList.UsersDetails && thirdLevelSubordinateList.UsersDetails.length > 0) {
      setSelectedThirdLevelSubordinateList(thirdLevelSubordinateList.UsersDetails);
    }
    if (fourthLevelSubordinateList && fourthLevelSubordinateList.UsersDetails && fourthLevelSubordinateList.UsersDetails.length > 0) {
      setSelectedFourthLevelSubordinateList(fourthLevelSubordinateList.UsersDetails);
    }

  }, [secondLevelSubordinateList, thirdLevelSubordinateList, fourthLevelSubordinateList])

  useEffect(() => {
    if(isSettingUpActivate){
      getMasterData()
    }
  }, [isSettingUpActivate]);
  useEffect(() => {
    if (getLoginCount.isFirstLogin) {
      history.push(`/app/profilesetting`);
    }
  }, [getLoginCount]);
  useEffect(() => {
    if (getLoginCount.isFirstLogin) {
      history.push(`/app/profilesetting`);
    }
    if ((RoleId === "RBH" || RoleId === "ABH") && channelid === 21) {
      const data = {
        userId: UserId,
        roleName: RoleId,
        viewFor: "SELF",
        subordinateUserIds: "all"
      }
      dispatch(getActivityTypeDetailsCountsByUserId(data))
    }
    dispatch(getLeadStatusList())
  }, []);

  const getMasterData = async () => {
    // const masterDataSyncTime = await getMasterSyncTime();/*commented for codesmells
    const masterSyncRequired = await isMasterSyncRequired();
    // if (masterSyncRequired) {
      setShowModalSetup(true);
      dispatch(setMenuDisabled({ isDisable: true }));
      dispatch(getPartnerDetails());
      dispatch(loadMasterData({ 'masterDataState': commonState.commonReducer, 'userState': commonState.userReducer }))
      const numberOfMillisecondsSinceEpoch = Date.now();
      addUpdateLastMasterSyncTime(numberOfMillisecondsSinceEpoch);
      setTimeout(() => {
        setShowModalSetup(false);
        dispatch(setMenuDisabled({ isDisable: false }));
        dispatch({
          type:"isSettingUpActivate",
          payload:false
        })
      }, 15000);
    // }
    dispatch(getAutoCompleteActivities({ userId: UserId }));

    // idealy it should be promise based, but current implementation does not support it
    //make loadMasterData method as a promise
  }
  const getBDMList = () => {
    if (UserId && RoleId && RoleId !== 'BDM') {
      dispatch(getBDMforABHlist(UserId, selectedView))
      dispatch(getTeamlist(UserId, selectedView))

    }
  };
  // const getSecondLevelSubordinateList = () => {
  //   if (UserId && RoleId) {
  //     dispatch(getSecondLevelSubordinatelist(UserId, 'RBH'))
  //   }
  // }
  const handleChange = (event) => {
    let levelRole;
    let data = {};
    switch (channelid) {
      case 1:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: "ZBH" })
            setRbhView(event.target.value ? false : true)
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ZBH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ZBH"))

            data = {
              userId: event.target.value ? event.target.value : UserId,
              roleName: event.target.value ? "ZBH" : UserId,
              viewFor: event.target.value ? "RBH" : "ZBH",
              subordinateUserIds: `all`
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
            break;
          case "ZBH":
            dispatch({ type: "viewFor", payload: "RBH" })
            setRbhView(event.target.value ? false : true)
            data = {
              userId: event.target.value ? event.target.value : UserId,
              roleName: event.target.value ? "RBH" : RoleId,
              viewFor: event.target.value ? "ABH" : "RBH",
              subordinateUserIds: 'all'
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
            dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "RBH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "RBH"))

            break;
          case "RBH":
            dispatch({ type: "viewFor", payload: "ABH" })
            data = {
              userId: event.target.value ? event.target.value : UserId,
              roleName: event.target.value ? "ABH" : RoleId,
              viewFor: event.target.value ? "BDM" : "ABH",
              subordinateUserIds: 'all'
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data));
            dispatch(getActivityTypeDetailsCountsByUserIdRBH(data));
            // setRbhView(event.target.value ? false : true)
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ABH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ABH"))
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 2:
        switch (RoleId) {
          case "NH":
            dispatch(getABHByZBH(event.target.value ? event.target.value : UserId, event.target.value ? "ABH" : "ZBH"));
            dispatch({ type: "viewFor", payload: "ZBH" })
            setRbhView(event.target.value ? false : true)
            break;
          case "ZBH":
            if (event.target.value !== 0) {
              const levelRole1 = ABHListForZBH?.filter(el => {
                if (el.userID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : RoleId))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : RoleId))
            dispatch({ type: "viewFor", payload: "ABH" })
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 3:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: "ZBH" })
            setRbhView(event.target.value ? false : true)
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ZBH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ZBH"))

            break;
          case "ZBH":
            dispatch({ type: "viewFor", payload: "RBH" })
            setRbhView(event.target.value ? false : true)
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "RBH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "RBH"))

            break;
          case "RBH":
            dispatch({ type: "viewFor", payload: "ABH" });
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ABH"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "ABH"))

            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 21:
        switch (RoleId) {
          case "RBH":
            dispatch({ type: "viewFor", payload: "ABH" })
            data = {
              userId: event.target.value ? event.target.value : UserId,
              roleName: event.target.value ? "ABH" : RoleId,
              viewFor: event.target.value ? "BDM" : "ABH",
              subordinateUserIds: 'all'
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data));
            // setRbhView(event.target.value ? false : true)
            if (event.target.value !== 0) {
              const levelRole1 = bdmList?.filter(el => {
                if (el.UserID === event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level
            }
            dispatch(getSecondLevelSubordinatelist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "BDM"))
            dispatch(getSecondLevelTeamlist(event.target.value ? event.target.value : UserId, levelRole ? levelRole : "BDM"))

            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      
      default:
        break;
    }

    setSelectedBDM(event.target.value);
    setSelectedSecondLevelSubordinate(0);
    setSelectedThirdLevelSubordinate(0);
    setSelectedFourthLevelSubordinate(null)

    if (event.target.value == 0) {
      setshowSecondDropdown(false);
      setshowThirdDropdown(false);
      setshowFourthDropdown(false);
      // setSelectedSecondLevelSubordinate(0);
    } else {
      setshowSecondDropdown(true)
    }
    dispatch({
      type: "SetBDMByABH",
      payload: `${event.target.value ? event.target.value : "all"}`
    })
  };
  const handleChangeSecondLevelSubordinate = (event) => {
    let levelRole;
    let data = {};
    switch (channelid) {
      case 1:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "RBH" : "ZBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ZBH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ZBH"))

            } else {
              let levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getThirdLevelTeamlist(event.target.value, levelRole))
            }
            data = {
              userId: event.target.value ? event.target.value : defaultSelectedBDM,
              roleName: event.target.value ? "RBH" : "ZBH",
              viewFor: event.target.value ? "ABH" : "RBH",
              subordinateUserIds: `all`
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
            break;
          case "ZBH":
            setRbhView(event.target.value ? true : false)
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "RBH" })
            data = {
              userId: event.target.value ? event.target.value : defaultSelectedBDM,
              roleName: event.target.value ? "ABH" : "RBH",
              viewFor: event.target.value ? "BDM" : "ABH",
              subordinateUserIds: "all",
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
            dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "RBH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "RBH"))
            } else {
              const levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID === event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getThirdLevelTeamlist(event.target.value, levelRole))
            }
            break;
          case "RBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            if (event.target.value === 0) {
              data = {
                userId: defaultSelectedBDM,
                roleName: "ABH",
                viewFor: "BDM",
                subordinateUserIds: `all`
              }
              dispatch(getActivityTypeDetailsCountsByUserId(data))
              dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
            }
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ABH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ABH"))
            } else {
              const levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID === event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getThirdLevelTeamlist(event.target.value, levelRole))
            }

            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 2:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "ZBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value !== 0 || event.target.value !== "ALL") {
              dispatch(getThirdLevelSubordinatelist(event.target.value, "ABH"))
              dispatch(getThirdLevelTeamlist(event.target.value, "ABH"))
            }
            break;
          case "ZBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            // if (event.target.value === 0 || event.target.value === "ALL") {
            //   dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ABH"))
            // } else {
            //   dispatch(getThirdLevelSubordinatelist(event.target.value, "BDM"))
            // }
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 3:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "RBH" : "ZBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ZBH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ZBH"))
            } else {
              let levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ZBH"))
            }
            break;
          case "ZBH":
            setRbhView(event.target.value ? true : false)
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "RBH" })
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "RBH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ZBH"))
            } else {
              let levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID == event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ZBH"))
            }
            break;
          case "RBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            // if (event.target.value === 0 || event.target.value === "ALL") {
            //   // dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ABH"))
            // } else {
            //   let levelRole1 = secondLevelSubordinateList?.UsersDetails.filter(el => {
            //     if (el.UserID == event.target.value) {
            //       return el;
            //     }
            //   })
            //   levelRole = levelRole1[0]?.Level;
            //   // dispatch(getThirdLevelSubordinatelist(event.target.value, levelRole))
            // }
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 21:
        switch (RoleId) {
          case "RBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            if (event.target.value === 0) {
              data = {
                userId: defaultSelectedBDM,
                roleName: "ABH",
                viewFor: "BDM",
                subordinateUserIds: `all`
              }
              dispatch(getActivityTypeDetailsCountsByUserId(data))
            }
            break
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      default:
        break;
    }
    setSelectedThirdLevelSubordinate(0);
    setSelectedFourthLevelSubordinate(null)

    if (event.target.value === 0 || event.target.value === 'ALL') {
      setshowThirdDropdown(false);
      setshowFourthDropdown(false);
      setSelectedSecondLevelSubordinate(0);
    } else {
      setshowThirdDropdown(true)
    }
    setSelectedSecondLevelSubordinate(event.target.value)
  }

  const handleChangeThirdLevelSubordinate = (event) => {
    let levelRole;
    let data = {};
    switch (channelid) {
      case 1:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "RBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getFourthLevelSubordinatelist(selectedSecondLevelSubordinate, "ABH"))
              dispatch(getFourthLevelTeamlist(selectedSecondLevelSubordinate, "ABH"))


            } else {
              const levelRole1 = thirdLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID === event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getFourthLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getFourthLevelTeamlist(event.target.value, levelRole))

            }
            data = {
              userId: event.target.value ? event.target.value : selectedSecondLevelSubordinate,
              roleName: event.target.value ? "ABH" : "RBH",
              viewFor: event.target.value ? "BDM" : "ABH",
              subordinateUserIds: `all`
            }
            dispatch(getActivityTypeDetailsCountsByUserId(data))
            break;
          case "ZBH":
            setRbhView(event.target.value ? true : false)
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            if (event.target.value === 0) {
              data = {
                userId: selectedSecondLevelSubordinate,
                roleName: "ABH",
                viewFor: "BDM",
                subordinateUserIds: `all`
              }
              dispatch(getActivityTypeDetailsCountsByUserId(data))
              dispatch(getActivityTypeDetailsCountsByUserIdRBH(data))
            }
            break;
          case "RBH":
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 2:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            setRbhView(event.target.value ? true : false)
            // if (event.target.value !== 0 || event.target.value !== "ALL") {
            //   dispatch(getThirdLevelSubordinatelist(event.target.value, "ABH"))
            // }
            break;
          case "ZBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ABH"))
              dispatch(getThirdLevelTeamlist(defaultSelectedBDM, "ABH"))

            } else {
              dispatch(getThirdLevelSubordinatelist(event.target.value, "BDM"))
              dispatch(getThirdLevelTeamlist(event.target.value, "BDM"))
            }
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 3:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "RBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getFourthLevelSubordinatelist(selectedSecondLevelSubordinate, "ABH"))
              dispatch(getFourthLevelTeamlist(selectedSecondLevelSubordinate, "ABH"))


            } else {
              const levelRole1 = thirdLevelSubordinateList?.UsersDetails.filter(el => {
                if (el.UserID === event.target.value) {
                  return el;
                }
              })
              levelRole = levelRole1[0]?.Level;
              dispatch(getFourthLevelSubordinatelist(event.target.value, levelRole))
              dispatch(getFourthLevelTeamlist(selectedSecondLevelSubordinate, "ABH"))

            }
            break;
          case "ZBH":
            setRbhView(event.target.value ? true : false)
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            break;
          case "RBH":
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      default:
        break;
    }
    setSelectedFourthLevelSubordinate(0);
    if (event.target.value === 0 || event.target.value === 'ALL') {
      setshowFourthDropdown(false);
      setSelectedThirdLevelSubordinate(0);
    } else {
      setshowFourthDropdown(true)
    }
    setSelectedThirdLevelSubordinate(event.target.value)
  }
  const handleChangeFourthLevelSubordinate = (event) => {
    switch (channelid) {
      case 1:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value === 0) {
              const data = {
                userId: selectedThirdLevelSubordinate,
                roleName: "ABH",
                viewFor: "BDM",
                subordinateUserIds: `all`
              }
              dispatch(getActivityTypeDetailsCountsByUserId(data))
            }
            break;
          case "ZBH":
            break;
          case "RBH":
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 2:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "ABH" : "ZBH" })
            setRbhView(event.target.value ? true : false)
            if (event.target.value !== 0 || event.target.value !== "ALL") {
              dispatch(getThirdLevelSubordinatelist(event.target.value, "ABH"))
              dispatch(getThirdLevelTeamlist(event.target.value, "ABH"))

            }
            break;
          case "ZBH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            if (event.target.value === 0 || event.target.value === "ALL") {
              dispatch(getThirdLevelSubordinatelist(defaultSelectedBDM, "ABH"))
              dispatch(getThirdLevelTeamlist(event.target.value, "ABH"))
            } else {
              dispatch(getThirdLevelSubordinatelist(event.target.value, "BDM"))
              dispatch(getThirdLevelTeamlist(event.target.value, "ABH"))
            }
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      case 3:
        switch (RoleId) {
          case "NH":
            dispatch({ type: "viewFor", payload: event.target.value ? "BDM" : "ABH" })
            break;
          case "ZBH":
            break;
          case "RBH":
            break;
          case "ABH":
            break;
          case "BDM":
            break;
        }
        break;
      default:
        break;
    }
    setSelectedFourthLevelSubordinate(event.target.value)
  }
  const logoutHandler = () => {
    setShowModal(!showModal);
    // this will reset the master data sync time, so on next login it will push all master data again
    resetMasterDataSyncTime().then((res) => {
      dispatch(logoutUserReducer());
    });
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    // clearIndexedDB()
    // clearCache()
    sessionStorage.clear();
    history.replace("/");
  };

  const selectView = (view) => {
    return (
      view ? 'SELF' : 'ALL'
    )
  }
  return (
    <div className="mainContainer dashboardDetail">
      <div className="dashboardHead">
        {
          !isOnline &&
          <Typography variant="h6" style={{ color: 'red', fontSize: '13px' }}>You are offline! to see actual data, enable internet connection</Typography>
        }
        <Typography variant="h4">My Dashboard</Typography>
        <div className="optionClick">
          {/* <PanToolIcon />
        <SettingsApplicationsIcon />
        <AppsIcon className="active" />
        <ViewListIcon /> */}
        </div>
      </div>


      {RoleId === "ABH" ? (
        <div className="button-container">
          <div>
            <div className="btn-inner-container">
              <Grid container>
                <Grid xs={6}>
                  <Button className={`myView ${showMyView && "active"}`} onClick={() => onMyViewClick("myView")}>My View</Button>
                </Grid>
                <Grid xs={6}>
                  <Button className={`myView ${showTeamView && "active"}`} onClick={() => onMyViewClick("teamView")}>Team View</Button>
                </Grid>
              </Grid>
            </div>
            {
              showTeamView &&
              <div className="dropdown-container">
                <Grid item xs={12} textAlign="right">
                  <p className="viewAll">View All</p>
                  <FormControl className="selectBx">
                    <Select
                      value={defaultSelectedBDM}
                      label=""
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      {
                        bdmList.length > 0 && bdmList.map((users) => {
                          return (
                            <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            }
          </div>
        </div>
      ) : (
        (RoleId === "NH" || RoleId === "RBH" || RoleId === "ZBH") &&
        <div className="button-container">
          <div>
            <div className="btn-inner-container">
              <Grid container>
                <Grid xs={6}>
                  <Button className={`myView ${showMyView && "active"}`} onClick={() => onMyViewClick("myView")}>My View</Button>
                </Grid>
                <Grid xs={6}>
                  <Button className={`myView ${showTeamView && "active"}`} onClick={() => onMyViewClick("teamView")}>Team View</Button>
                </Grid>
              </Grid>
            </div>
            {
              showTeamView &&
              <div className="dropdown-container">
                <Grid container>
                  <Grid item xs={6} sm={3} textAlign="right">
                    <p className="viewAll">View All</p>
                    <FormControl className="selectBx">
                      <Select
                        value={defaultSelectedBDM}
                        label=""
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {
                          (channelid !== 2 ) && bdmList.length > 0 && bdmList.map((users) => {
                            return (
                              <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                            )
                          })
                        }
                        {
                          channelid == 2 && RoleId === "NH" && bdmList.length > 0 && bdmList.map((users) => {
                            return (
                              <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                            )
                          })
                        }
                        {
                          channelid == 2 && RoleId !== "NH" && ABHListForZBH.length > 0 && ABHListForZBH.map((users) => {
                            return (
                              <MenuItem key={users.userID} value={users.userID}>{users.userName}</MenuItem>
                            )
                          })
                        }
                        
                      </Select>
                    </FormControl>
                  </Grid>
                  {showSecondDropdown &&
                    <Grid item xs={6} sm={3} textAlign="right">
                      <p className="viewAll">View All</p>
                      <FormControl className="selectBx">
                        <Select
                          value={selectedSecondLevelSubordinate}
                          label=""
                          onChange={handleChangeSecondLevelSubordinate}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          {/* <MenuItem value={"ALL"}>All</MenuItem> */}
                          {
                            channelid !== 2 && selectedSecondLevelSubordinateList.length > 0 && selectedSecondLevelSubordinateList.map((users) => {
                              return (
                                <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                              )
                            })
                          }{
                            channelid === 2 && RoleId === "ZBH" && selectedSecondLevelSubordinateList.length > 0 && selectedSecondLevelSubordinateList.map((users) => {
                              return (
                                <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                              )
                            })
                          }
                          {
                            channelid === 2 && RoleId === "NH" && ABHListForZBH.length > 0 && ABHListForZBH.map((users) => {
                              return (
                                <MenuItem key={users.userID} value={users.userID}>{users.userName}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>}
                  {showThirdDropdown && (((RoleId === "NH" || RoleId === "ZBH") && channelid !== 2) || (RoleId === "NH" && channelid === 2)) &&
                    <Grid item xs={6} sm={3} textAlign="right">
                      <p className="viewAll">View All</p>
                      <FormControl className="selectBx">
                        <Select
                          value={selectedThirdLevelSubordinate}
                          label=""
                          onChange={handleChangeThirdLevelSubordinate}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          {/* <MenuItem value={"ALL"}>All</MenuItem> */}
                          {
                            selectedThirdLevelSubordinateList.length > 0 && selectedThirdLevelSubordinateList.map((users) => {
                              return (
                                <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>}
                  {showFourthDropdown && RoleId === "NH" && channelid !== 2 &&
                    <Grid item xs={6} sm={3} textAlign="right">
                      <p className="viewAll">View All</p>
                      <FormControl className="selectBx">
                        <Select
                          value={selectedFourthLevelSubordinate}
                          label=""
                          onChange={handleChangeFourthLevelSubordinate}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          {/* <MenuItem value={"ALL"}>All</MenuItem> */}
                          {
                            selectedFourthLevelSubordinateList.length > 0 && selectedFourthLevelSubordinateList.map((users) => {
                              return (
                                <MenuItem key={users.UserID} value={users.UserID}>{users.UserName}</MenuItem>
                              )
                            })
                          }

                        </Select>
                      </FormControl>
                    </Grid>}
                </Grid>
              </div>
            }
          </div>
        </div>)
      }
      {
        RoleId === "ABH" && showTeamView ?
          <DashboardMyTeamLayout
            selectedBDM={defaultSelectedBDM}
            selectedView={selectView(showMyView)}
            roleId={RoleId}
          >

          </DashboardMyTeamLayout> : (
            (RoleId === "NH" || RoleId === "RBH" || RoleId === "ZBH") ?
              <DashboardNHRBHZBHMyTeamLayout
                selectedBDM={defaultSelectedBDM}
                selectedView={showMyView ? selectView(showMyView) : RoleId === "NH" ? 'ZBH' : RoleId === "RBH" ? "ABH" : RoleId === "ZBH" ? "RBH" : selectView(showMyView)}
                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                viewFor={selectedView}
                rbhView={rbhView}
              /> :
              <DashboardLayout
                selectedBDM={defaultSelectedBDM}
                selectedView={selectView(showMyView)}
                roleId={RoleId}></DashboardLayout>
          )
      }
      <ModalPopup
        className="modalDiv"
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size={"sm"}
        centered={true}
        modelHeader={"Offline session expire!"}
        modelContent={
          <div className="footerButtons">
            <p>Your session will expire in 1 day, please login online to continue.</p>
            <span>
              <Button
                onClick={() => {
                  logoutHandler();
                }}
              >
                Login
              </Button>
            </span>
          </div>
        }
        backdrop={"static"}
      />
      <ModalPopup
        className="modalDiv"
        show={showModalSetup ? showModalSetup : modalLoactionAccess}
        onHide={() => setShowModalSetup(!(showModalSetup ? showModalSetup : modalLoactionAccess))}
        size={"sm"}
        centered={true}
        modelContent={showModalSetup ? <></> : <Button onClick={handleClose}>CLOSE</Button>}
        modelHeader={showModalSetup ? <>
         Setting up app, Please wait...
          <PercentageBar target={15}/> </>
         : "Please enable your location"}
        backdrop={"static"}
      />

      {/* <ModalPopup
        show={showModalSetup}
        onHide={() => setShowModalSetup(!showModalSetup)}
        size={"sm"}
        centered={true}
        modelHeader={"Please enable your location"}
        modelContent={<Button onClick={handleClose}>CLOSE</Button>}
        backdrop={"static"}
      /> */}

    </div>
  );
}
export default withRouter(Home);
