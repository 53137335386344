import moment from "moment"
import { datedata } from './utils';

export const CLIENT_ID = 'F1'
export const DEFAULT_DEVICE_ID = '3fa85f64-5717-4562-b3fc-2c963f66afa6'

export const ROLE_BDM = 'BDM'
export const ROLE_ABH = 'ABH'
export const ROLE_FPM = "FPM"
export const ROLE_NH = "NH"
export const ROLE_RBH = "RBH"
export const ROLE_ZBH = "ZBH"

export const ZBH_RBH_ABH_BDM = "ZBH,RBH,ABH,BDM";
export const ZBH_ABH_BDM = "ZBH,ABH,BDM";
export const RBH_ABH_BDM = "RBH,ABH,BDM";
export const ABH_BDM = "ABH,BDM";





export const BRANCH_VISIT = "BRANCH_VISIT"
export const HNI_MEET = "HNI_MEET"
export const ICE_E_SAMPARK = "ICE/E-SAMPARK_ACTIVITY"
export const BRANCH_TRAINING = "BRANCH_TRAINING"
export const JOINT_SALES_CALL_WITH_SRM = "JOINT_SALES_CALL_WITH_SRM"
export const EXPENCE_CLAIM = "EXPENCE_CLAIM"
export const FINISH_DAY = "FINISH_DAY_ACTIVITY"
export const KYB = "KYB"
export const BDF = 'BDF_(BRANCH_FOUNDATION_DAY)'
export const ICE_FOR_LOAN = "ICE_FOR_LOAN_ACTIVITY"
export const HNI_LEAD_GENERATION = "HNI_LEAD_GENERATION"
export const ICE_FOR_LOAN_ACTIVITY = "ICE_FOR_LOAN_ACTIVITY"
export const SME_LF = "SME_LF"
export const SME_CELL = "SME_CELL"
export const BRANCH = "BRANCH"
export const COLLATE_THE_UNQUALIFIED_LEADS_DATA = "COLLATE_THE_UNQUALIFIED_LEADS_DATA"
export const REGIONAL_OFFICE_CONNECT = "REGIONAL_OFFICE_CONNECT"
export const BOB_QLIK = "BOB_QLIK" // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2

export const TRAINING = "TRAINING";
export const MONTHLY_REVIEW = "MONTHLY_REVIEW";
export const MEETING_VISIT = "MEETING/VISIT";
export const CALLING_TO_VLE_RAP_DM = "CALLING_TO_VLE/RAP/DM";

//broca activtity

export const BUSINESS_DEVELOPMENT_ACTIVITY = "BUSINESS_DEVELOPMENT_ACTIVITY";
export const PARTNER_VISIT = "PARTNER_VISIT";
export const PRODUCT_TRAINING = "PRODUCT_TRAINING";
export const BIRTHDAY_CELEBRATION = "BIRTHDAY_CELEBRATION";
export const PRODUCT_LAUNCH = "PRODUCT_LAUNCH";
export const BRANCH_HEAD_MEET = "BRANCH_HEAD_MEET";
export const BRANCH_FOUNDATION_DAY = "BRANCH_FOUNDATION_DAY";
export const JOINT_CALL_WITH_PARTNER = "JOINT_CALL_WITH_PARTNER";
export const LIVE_LEAD_GENERATION = "LIVE_LEAD_GENERATION";
export const PARTNER_BRANCH_VISIT_PLAN = "PARTNER_BRANCH_VISIT_PLAN";
export const TEAM_REVIEW = "TEAM_REVIEW";


export const TRAINING_FOR = "TrainingFor";
export const MONTHLY_REVIEW_FOR = "ReviewWith";
export const MEETING_VISIT_FOR = "MeetingWith";

export const TRAINING_ACTIVITY = "TRAINING_ACTIVITY";
export const NEW_BUSINESS = "NEW_BUSINESS";
export const NEW_RECRUITMENT_ACTIVITY = "NEW_RECRUITMENT_ACTIVITY";
export const REVIEW_MEETING = "REVIEW_MEETING";
export const PERSISTANCY = "persistencylead";
export const PERSISTANCYBANCA = "persistencyleadbanca";
export const CAMPAIGNLEAD = "campaignLead";

export const GCLLEAD = "GCL_Lead_Mapping";
export const MSMELEAD = "MSMELeadMapping";
export const LEAD = "lead";
export const RECRUITMENTLEAD = "Recruitmentlead"

export const STATUS_ACTIVITY_PLANNED = 'ACTIVITY_PLANNED'
export const STATUS_ACTIVITY_DRAFTED = 'ACTIVITY_DRAFTED'
export const STATUS_ACTIVITY_CANCELLED = 'ACTIVITY_CANCELLED'
export const STATUS_ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED'

export const SUB_ACTIVITY_ICE = 'ICE'
export const SUB_ACTIVITY_E_SAMPARK = 'ESAMPARK'
export const FACE_TO_FACE = "FACE_TO_FACE"
export const VIDEO = "VIDEO"
export const WEB = "WEB"

export const UTILITY_MOBILE = 'MOBILE'
export const UTILITY_DATA_CARD = 'DATA_CARD'

export const PUBLIC_TRANSPORT = "PT"
export const SELF_OWNED_TRANSPORT = "SOT"
export const TWO_WHEELER = "Two_Wheeler"
export const FOUR_WHEELER = "Four_Wheeler"

export const ACTIVITY_RESCHEDULED = 'ACTIVITY_RESCHEDULED'
export const ACTIVITY_CANCELLED = 'ACTIVITY_CANCELLED'
export const ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED'
export const ACTIVITY_UPDATE_CALLS = "ACTIVITY_UPDATE_CALLS"
export const ACTIVITY_CALLING_VLE = "ACTIVITY_CALLING_VLE"
export const JOINTCALL_COMPLETED = "JOINTCALL_COMPLETED"
export const JOINTCALL_CANCELLED = "JOINTCALL_CANCELLED"
export const JOINTCALL_RESCHEDULED = "JOINTCALL_RESCHEDULED"


export const DROPDOWN_ACTIVITY_TYPE = 'ActivityID'
export const DROPDOWN_EXPENCE_CLAIM = 'IsClaimRequired'
export const DROPDOWN_MODE_OF_TRANSPORT = 'TransportModeID'

export const CALLBACK = "CALLBACK";
export const DROPPED = "DROPPED";
export const CONVERTED = "CONVERTED";
export const MEETINGSCHEDULE = "MEETINGSCHEDULE";
export const REASSIGN = "REASSIGN";
export const ASSIGN = "ASSIGN"
export const FOLLOWUP = "FOLLOWUP";
export const RESCHEDULE = "RESCHEDULE";
export const APPLICATION_CREATED = "APPLICATION_CREATED";
export const INTRESTED = "INTRESTED";
export const PREMIUMCOLLECTED = "PREMIUM_COLLECTED";
export const GCLACTIVITYTYPE = "GCLACTIVITYTYPE";
export const LOAN_SANCTION_STAGE = "LOAN_SANCTION_STAGE"
export const LOAN_PRE_SANCTION_STAGE = "LOAN_PRE-SANCTION_STAGE"
export const LOAN_DISBURSEMENT_STAGE = "LOAN_DISBURSEMENT_STAGE"
export const LOAN_NOT_PRE_SANCTION_STAGE = "LOAN_NOT_PRE-SANCTION_STAGE"
export const LOAN_NOT_SANCTION_STAGE = "LOAN_NOT_SANCTION_STAGE"
export const MEMBER_FORM_COLLECTED = "MEMBER_FORM_COLLECTED"
export const MEDICALSCHEDULED = "MEDICALSCHEDULED"
export const MEDICAL_COMPLETED = "MEDICAL_COMPLETED"
export const COI_ISSUED = "COI_ISSUED"
export const CONNECTED = "CONNECTED";
export const NOT_CONNECTED = "NOT_CONNECTED";


export const BANCA_LEAD = "BANCA_LEAD";
export const BUSSINESS_LEAD = "BUSSINESS_LEAD";
export const RECRUITMENT_LEAD = "RECRUITMENT_LEAD";
export const FPM_LEAD = "FPM_LEAD";
export const BROCA_LEAD = "BROCA_LEAD";


export const SCHEDULE_STRUCTURED_REVIEW_BDM = "SCHEDULE_STRUCTURED_REVIEW_BDM";
export const BRANCH_VISIT_PLAN_BDM = "BRANCH_VISIT_PLAN_BDM";
export const REGIONAL_OFFICE_MEETING_ABH = "REGIONAL_OFFICE_MEETING_ABH";
export const SALES_TRAINING_PLAN_BDM = "SALES_TRAINING_PLAN_BDM";
export const JOINT_CALLS_BDM = "JOINT_CALLS_BDM";
export const CIRCULAR_FOR_SALES_ENABLEMENT_ABH = "CIRCULAR_FOR_SALES_ENABLEMENT_ABH"

export const checkInConfig = { API_KEY: 'AIzaSyDLfU_xloHimTlXcfe8scX2YvjS8II6hbo', LANGUAGE: 'en', REGION: 'es' }

export const levelbyUser = (channelid, roleId, levelofRole) => {
  let level;
  switch (channelid) {
    case 1:
    case 3:
    case 21:
      switch (roleId) {
        case "NH":
          switch (levelofRole) {
            case "First":
              level = "ZBH";
              break;
            case "Second":
              level = "RBH";
              break;
            case "Three":
              level = "ABH";
              break;
            case "Four":
              level = "BDM";
              break;
            case "Five":
              level = "SELF";
              break;
          }
          break;
        case "ZBH":
          switch (levelofRole) {
            case "First":
              level = "RBH";
              break;
            case "Second":
              level = "ABH";
              break;
            case "Three":
              level = "BDM";
              break;
            case "Four":
              level = "SELF";
              break;
          }
          break;
        case "RBH":
          switch (levelofRole) {
            case "First":
              level = "ABH";
              break;
            case "Second":
              level = "BDM";
              break;
            case "Three":
              level = "SELF";
              break;
          }
          break;
      }
      break;
    case 2:
      switch (roleId) {
        case "NH":
          switch (levelofRole) {
            case "First":
              level = "ZBH";
              break;
            case "Second":
              level = "ABH";
              break;
            case "Three":
              level = "BDM";
              break;
            case "Four":
              level = "SELF";
              break;
          }
          break;
        case "ZBH":
          switch (levelofRole) {
            case "First":
              level = "ABH";
              break;
            case "Second":
              level = "BDM";
              break;
            case "Three":
              level = "SELF";
              break;
          }
          break;
      }
      break;
    
  }
  return level;

}
// export const ACTIVITY_TYPE_OPTIONS_BANKA = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Branch Visit", "label": "Branch Visit", "value": BRANCH_VISIT },
//     { "key": "HNI Meet", "label": "HNI Meet", "value": HNI_MEET },
//     { "key": "ICE/e-Sampark Activity", "label": "ICE/e-Sampark Activity", "value": ICE_E_SAMPARK },
//     { "key": "Branch Training", "label": "Branch Training", "value": BRANCH_TRAINING },
//     { "key": "Expence Claim", "label": "Expense Claim", "value": EXPENCE_CLAIM },
//     { "key": "Finish Day Activity", "label": "Finish Day Activity", "value": FINISH_DAY },
//     { "key": "KYB", "label": "KYB", "value": KYB }
// ]

export const ACTIVITY_TYPE_OPTIONS_SALES_MANAGEMENT = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Schedule Structured Review (BDM)", "label": "Schedule Structured Review (BDM)", "value": SCHEDULE_STRUCTURED_REVIEW_BDM },
  { "key": "Branch Visit Plan (BDM)", "label": "Branch Visit Plan (BDM)", "value": BRANCH_VISIT_PLAN_BDM },
  { "key": "Regional Office Meeting (ABH)", "label": "Regional Office Meeting (ABH)", "value": REGIONAL_OFFICE_MEETING_ABH },
  { "key": "Sales Training Plan (BDM)", "label": "Sales Training Plan (BDM)", "value": SALES_TRAINING_PLAN_BDM },
  { "key": "Joint Calls (BDM)", "label": "Joint Calls (BDM)", "value": JOINT_CALLS_BDM },
  { "key": "Circular for Sales Enablement (ABH)", "label": "Circular for Sales Enablement (ABH)", "value": CIRCULAR_FOR_SALES_ENABLEMENT_ABH },
]

export const activityCorprteGroup = [
  { 'key': 'Select', 'label': 'Select', 'value': '' },
  { 'key': 'BRANCH_VISIT', 'label': 'Branch Visit', 'value': '63' },
  { 'key': 'HNI_MEET', 'label': 'HNI Meet', 'value': '64' },
  { 'key': 'ICE/E-SAMPARK_ACTIVITY', 'label': 'ICE/e-Sampark', 'value': '65' },
  { 'key': 'BRANCH_TRAINING', 'label': 'Branch Training', 'value': '66' },
  { 'key': 'BDF_(BRANCH_FOUNDATION_DAY)', 'label': 'Branch Foundation Day', 'value': '67' },
  { 'key': 'FINISH_DAY_ACTIVITY', 'label': 'Finish Day Activity', 'value': '68' }
]


export const ABH_SALES_ACTIVITY_TYPES_ID = [26, 27, 28, 29, 30, 31];
export const RBH_SALES_ACTIVITY_TYPES_ID = [38, 39, 40, 41, 42, 43, 44];
export const RBH_SALES_ACTIVITY_TYPES_ID_NEW_BANCA = [75, 76, 77, 78, 79, 80];
export const ABH_SALES_ACTIVITY_TYPES = ["", "26", "27", "28", "29", "30", "31"];
export const ABH_COMMON_ACTIVITY_TYPES_ID = [1, 2, 3, 4, 5, 6, 25];
export const BDM_COMMON_ACTIVITY_TYPES_ID = [1, 2, 3, 4, 5, 6];
export const BROCA_COMMON_ACTIVITY_TYPES_ID = ['', "45", "46", "47", "48", "49", "50", "51", "52", "53"];
export const CORPORATE_ACTIVITY_TYPES_ID = ['', "47", "48", "49", "50"];


export const PARTNER_ENGAGEMENT = ["1", "2", "3", "4", "5", "6"]
export const MUST_DO = ["7", "8", "9", "10"]
export const BROCA_SMA_ACTIVITY_TYPES_ID = ['', "54", "55"]
export const BROCA_SMA_ACTIVITY_TYPES_ID_CALENDER = [54, 55]
export const errorStatusCodeForSuccess = ["0000", "0", 0];

export const GCL_LEAD_STATUS_ID = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

export const BANCA_ABH_LEAD_HIDE_SPOT_LEAD = [7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
export const BANCA_FPM_LEAD_HIDE_SPOT_LEAD = [7, 8, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
export const BANCA_ABH_LEAD_SHOW_SPOT_LEAD = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

// export const ACTIVITY_TYPE_OPTIONS_TYPE_OF_TRAINING = [
//   {"key":"Select", "label":"Select","value":""},
//   {"key":"Product Training ","label":"Product Training","value":PRODUCT_TRAINING},
//   {"key":"Objection Handling","label":"Objection Handling","value":OBJECTIOIN_HANDLING},
//   {"key":"Closing Techniques","label":"Closing Techniques","value":CLOSING_TECHNIQUES},
//   {"key":"Prospecting","label":"Prospecting","value":PROSPECTING},
//   {"key":"Soft Sales Training","label":"Soft Sales Training","value":SOFT_SALES_TRAINING},
//   {"key":"S/W Training","label":"S/W Training","value":S_W_TRAINING},
//   {"key":"UW Process","label":"UW Process","value":UW_PROCESS},
//   {"key":"Others","label":"Others","value":OTHERS},
// ]

export const SUB_ACTIVITY_TYPE_OPTIONS = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "ICE", "label": "Branch Visit", "value": SUB_ACTIVITY_ICE },
  { "key": "HNI Meet", "label": "eSampark", "value": SUB_ACTIVITY_E_SAMPARK }
]

export const SUB_ACTIVITY_TYPE_OPTIONS_JOINT_SALES_CALL_WITH_SRM = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "JointSalesCallWithSRM", "label": "Joint Sales Call with SRM", "value": JOINT_SALES_CALL_WITH_SRM }
]

// export const MEETING_TYPES_JOINT_SALES_CALL_WITH_SRM = [
//   { "key": "Select", "label": "Select", "value": "" },
//   { "key": "FaceToFace", "label": "Face to Face", "value": FACE_TO_FACE },  
//   { "key": "Call", "label": "Call", "value": CALLING_TO_VLE_RAP_DM },  
//   { "key": "Video", "label": "Video", "value": VIDEO },
//   { "key": "Web", "label": "Web", "value": WEB }
// ]

export const UTILITY_CATEGORY_OPTIONS = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Mobile", "label": "Mobile", "value": UTILITY_MOBILE },
  { "key": "Data Card", "label": "Data Card", "value": UTILITY_DATA_CARD }
]

export const CLAIM_TRAVEL_EXPENCE_OPTIONS = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Yes", "label": "Yes", "value": "YES" },
  { "key": "No", "label": "No", "value": "NO" }
]

export const MODE_OF_TRANSPORT_OPTIONS = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Public Transport", "label": "Public Transport", "value": PUBLIC_TRANSPORT },
  { "key": "Self Owned Transport", "label": "Self Owned Transport", "value": SELF_OWNED_TRANSPORT }
]

export const PROFESSION = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Public Sector", "label": "Public Sector", "value": "Public Sector" },
  { "key": "Private Sector", "label": "Private Sector", "value": "Private Sector" }
]

export const ACTIVITY_DONE_WITH = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "ZMA Name", "label": "ZMA Name", "value": "ZMA Name" },
  { "key": "Premier ZMA Name", "label": "Premier ZMA Name", "value": "Premier ZMA Name" }
]

export const INCOME_GROUP = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "1L", "label": "1L", "value": "1L" },
  { "key": "1L to 5L", "label": "1 to 5L", "value": "1 to 5L" },
  { "key": "5L above", "label": ">5L", "value": "5L above" }
]

export const ENGAGMENT = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Part Time", "label": "Part Time", "value": "Part Time" },
  { "key": "Full Time", "label": "Full Time", "value": "Full Time" }
]

// export const SP = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Staff", "label": "Part Time", "value": "Staff" },
//     { "key": "SRM", "label": "Full Time", "value": "SRM" },
//     { "key": "Wealth Execuitve", "label": "Wealth Execuitve", "value": "Wealth Execuitve" }
// ]

// export const SALUTATION = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Mr", "label": "Mr", "value": "Mr" },
//     { "key": "Mrs", "label": "Mrs", "value": "Mrs" },
//     { "key": "MS", "label": "MS", "value": "MS" },
//     { "key": "DR", "label": "DR", "value": "DR" }
// ]

export const PRIORITY = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Hot", "label": "Hot", "value": "Hot" },
  { "key": "Warm", "label": "Warm", "value": "Warm" },

]

// export const REASON_FOR_DROP = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Not Intrested", "label": "Not Intrested", "value": "notIntrested" },
//     { "key": "Product not Suitable", "label": "Product not Suitable", "value": "productNotSuitable" },
//     { "key": "Others", "label": "Others", "value": "Others" }];
// export const REASON_FOR_FOLLOWUP = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Not Reachable", "label": "Not Reachable", "value": "Not Reachable" },
//     { "key": "Switched Off", "label": "Switched Off", "value": "Switched Off" },
//     { "key": "Ringing Response", "label": "Ringing Response", "value": "Ringing Response" },
//     { "key": "Customer Disconnect", "label": "Customer Disconnect", "value": "Customer Disconnect" },
//     { "key": "Customer Request", "label": "Customer Request", "value": "Customer Request" },
//     { "key": "Other", "label": "Other", "value": "Other" },
// ];

// export const REASON_FOR_RESCHEDULE = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Customer Unavailable", "label": "Customer Unavailable", "value": "Customer Unavailable" },
//     { "key": "Customer Not Contactable", "label": "Customer Disconnect", "value": "Customer Not  Contactable" },
//     { "key": "Customer Request", "label": "Customer Request", "value": "Customer Request" },
//     { "key": "Other", "label": "Other", "value": "Other" },
// ];

// export const MEETING_TYPE = [
//     { "key": "Select", "label": "Select", "value": "" },
//     { "key": "Visit", "label": "Visit", "value": 1 },
//     { "key": "Meeting", "label": "Meeting", "value":2 },
//     { "key": "Sales development activity", "label": "Sales development activity", "value": 2 },
//     { "key": "Customer Meet", "label": "Customer Meet", "value": 3 },
//     { "key": "Customer Grievance", "label": "Customer Grievance", "value": 4 },
//     { "key": "(RAP/VLE Grievance)", "label": "(RAP/VLE Grievance)", "value": 5 },
// ];
export const MEETING_TYPE_ZMA_PZMA = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "ZMA", "label": "ZMA", "value": 1 },
  { "key": "Premier ZMA", "label": "Premier ZMA", "value": 2 }

];
export const CONFIGS = {
  "NEW_ACTIVITY_FORM": "newactivity",
  "UPDATE_ACTIVITY_FORM": "existingactivity",
  "UPDATE_LEAD_FORM": "updateLead",
  "MASTER_DATA_TABLES": {
    "RESCHEDULE_REASON": { "TABLE_NAME": "RescheduleReason", "KEY": "RescheduleReasonID", "LABEL": "RescheduleReasonName", "CODE": "RescheduleReasonID", "CHANNEL_ID": "", "FIELD_NAME": "RescheduleReasonID" },
    "CALLBACK_REASON": { "TABLE_NAME": "CallBackReason", "KEY": "CallBackReasonID", "LABEL": "CallBackReasonName", "CODE": "CallBackReasonCode", "CHANNEL_ID": "" },
    "FOLLOWUP_REASON": { "TABLE_NAME": "FollowupReason", "KEY": "FollowupReasonID", "LABEL": "FollowupReasonName", "CODE": "FollowupReasonID", "CHANNEL_ID": "", "FIELD_NAME": "FollowupReasonID" },
    "DROPPED": { "TABLE_NAME": "droppedreason", "KEY": "DroppedReasonID", "LABEL": "DroppedReasonName", "CODE": "DroppedReasonCode", "CHANNEL_ID": "" },
    "ACTIVITY": { "TABLE_NAME": "Activities", "KEY": "ActivityId", "LABEL": "ActivityName", "CODE": "ActivityCode", "CHANNEL_ID": "ChannelID" },
    "ACTIVITY_FOR_HNI": { "TABLE_NAME": "ActivitiesForHNI", "KEY": "ActivityId", "LABEL": "ActivityName", "CODE": "ActivityCode", "CHANNEL_ID": "ChannelID" },
    "SRM_LIST": { "TABLE_NAME": "SRMList", "KEY": "SRMID", "LABEL": "FullName", "CODE": "SRMCode", "CHANNEL_ID": "ChannelID" },
    "SMA_ACTIVITY_DURATION": { "TABLE_NAME": "SmaActivityDuration", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "SmaActivityDuration" },
    // "SMA_TRAINING_TYPE": { "TABLE_NAME": "SMATrainingType", "KEY": "SMATrainingTypeID", "LABEL": "SMATrainingType", "CODE": "SMATrainingType", "CHANNEL_ID": "", "FIELD_NAME": "SmaTrainingTypeID" },
    // "SMA_ACTIVITY_TYPE": { "TABLE_NAME": "SMAActivityType", "KEY": "SMAActivityTypeID", "LABEL": "SMAActivityType", "CODE": "SMAActivityTypeCode", "CHANNEL_ID": "", "FIELD_NAME": "SMAActivityTypeID" },
    "EXPENSE_TYPE": { "TABLE_NAME": "ExpenseType", "KEY": "ExpenseTypeID", "LABEL": "ExpenseTypeName", "CODE": "ExpenseTypeCode", "CHANNEL_ID": "" },
    "SUB_ACTIVITY": { "TABLE_NAME": "ActivitySubType", "KEY": "ActivitySubTypeId", "LABEL": "ActivitySubTypeName", "CODE": "ActivitySubTypeCode", "CHANNEL_ID": "" },
    "FPMFPH_MEETING_TYPE": { "TABLE_NAME": "FPMFPHMeetingType", "KEY": "ID", "LABEL": "MeetingTypeName", "CODE": "MeetingTypeCode", "CHANNEL_ID": "" },
    "JOINT_SALES_CALL_WITH_SRM_SUB_ACTIVITY": { "TABLE_NAME": "JointSalesCallWithSRMActivitySubType", "KEY": "JointSalesCallWithSRMActivitySubTypeId", "LABEL": "JointSalesCallWithSRMActivitySubType", "CODE": "JointSalesCallWithSRMActivitySubType", "CHANNEL_ID": "" },
    "MODE_OF_TRANSPORT": { "TABLE_NAME": "ModeofTransport", "KEY": "TransportModeID", "LABEL": "TransportMode", "CODE": "TransportModeCode", "CHANNEL_ID": "", "FIELD_NAME": "TransportModeID" },
    "BRANCHES": { "TABLE_NAME": "Branches", "KEY": "BranchID", "LABEL": "BranchName", "CODE": "BranchCode", "CHANNEL_ID": "" },
    "VEHICLE_TYPE": { "TABLE_NAME": "VehicleType", "KEY": "VehicleTypeID", "LABEL": "VehicleTypeName", "CODE": "VehicleTypeCode", "CHANNEL_ID": "", "FIELD_NAME": "VehicleTypeID" },
    "ACTIVITY_STATUS": { "TABLE_NAME": "ActivityStatus", "KEY": "ActivityStatusId", "LABEL": "ActivityStatus", "CODE": "ActivityStatusCode", "CHANNEL_ID": "", "FIELD_NAME": "" },
    "PROFESSION_TYPE": { "TABLE_NAME": "Profession", "KEY": "ProfessionID", "LABEL": "ProfessionName", "CODE": "ProfessionCode", "CHANNEL_ID": "" },
    "INCOMEGROUP": { "TABLE_NAME": "IncomeGroupDetails", "KEY": "ProfessionID", "LABEL": "ProfessionName", "CODE": "ProfessionCode", "CHANNEL_ID": "" },
    "ENGAGEMENT": { "TABLE_NAME": "Engagement", "KEY": "VehicleTypeID", "LABEL": "VehicleTypeName", "CODE": "VehicleTypeCode", "CHANNEL_ID": "" },
    "PRODUCTS": { "TABLE_NAME": "ProductDetails", "KEY": "ProductId", "LABEL": "ProductName", "CODE": "ProductId", "CHANNEL_ID": "", "FIELD_NAME": "ProductId" },
    "SALUTATION": { "TABLE_NAME": "Salutation", "KEY": "SalutationID", "LABEL": "SalutationName", "CODE": "SalutationCode", "CHANNEL_ID": "", "FIELD_NAME": "SalutationID" },
    "LEADS": { "TABLE_NAME": "LeadType", "KEY": "LeadTypeID", "LABEL": "LeadTypeName", "CODE": "LeadTypeCode", "CHANNEL_ID": "", "FIELD_NAME": "LeadTypeID" },
    "SPTYPES": { "TABLE_NAME": "SPType", "KEY": "SPTypeID", "LABEL": "SPTypeName", "CODE": "SPTypeCode", "CHANNEL_ID": "", "FIELD_NAME": "SPTypeID" },
    "Configuration_Setting": { "TABLE_NAME": "Configuration_Setting", "KEY": "SettingValue", "LABEL": "SettingName", "CODE": "SettingName", "CHANNEL_ID": "", "FIELD_NAME": "timerSettingsForRedirect" },
    "GoalType": { "TABLE_NAME": "GoalType", "KEY": "GoalId", "LABEL": "GoalName", "CODE": "GoalCode", "CHANNEL_ID": "", "FIELD_NAME": "GoalType" },
    "LEADSOURCE": {
      "TABLE_NAME": "LeadSource", "KEY": "LeadSourceID", "LABEL": "LeadSourceName", "CODE": "LeadSourceCode", "CHANNEL_ID": "", "FIELD_NAME": "LeadSourceID"
    },
    "LEAD_CALLBACK_REASON": {
      "TABLE_NAME": "CallBackReason", "KEY": "CallBackReasonID", "LABEL": "CallBackReasonName",
      "CODE": "CallBackReasonID", "CHANNEL_ID": "", "FIELD_NAME": "CallBackReasonID"
    },
    "DROPPED_REASON": {
      "TABLE_NAME": "droppedreason", "KEY": "DroppedReasonID", "LABEL": "DroppedReasonName",
      "CODE": "DroppedReasonID", "CHANNEL_ID": "", "FIELD_NAME": "DroppedReasonID"
    },
    "TRAINING_FOR": { "TABLE_NAME": "TrainingFor", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "MONTHLY_REVIEW_FOR": { "TABLE_NAME": "ReviewWith", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "MEETING_VISIT_FOR": { "TABLE_NAME": "MeetingWith", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "STATE_ID": { "TABLE_NAME": "State", "KEY": "StateID", "LABEL": "StateName", "CODE": "StateCode", "CHANNEL_ID": "", "FIELD_NAME": "StateID" },
    "DISRICT": { "TABLE_NAME": "District", "KEY": "DistrictID", "LABEL": "DistrictName", "CODE": "DistrictCode", "CHANNEL_ID": "", "FIELD_NAME": "DistrictID" },
    "DISRICT_ID": {
      "TABLE_NAME": "District", "KEY": "DistrictID", "LABEL": "DistrictName", "CODE": "DistrictCode", "CHANNEL_ID": "",
      "FIELD_NAME": "DistrictID", "StateID": "StateID"
    },
    "MEETING_TYPE": { "TABLE_NAME": "MeetingType", "KEY": "ID", "LABEL": "MeetingTypeName", "CODE": "ID", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "ACTIVITY_DONE_WITH": { "TABLE_NAME": "ActivityDoneWith", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "RECRUITMENT_FOR": { "TABLE_NAME": "RecruitmentFor", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "VISIT_TO": { "TABLE_NAME": "VisitTo", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "ID" },
    "NOTIFICATION_CATEGORY": {
      "TABLE_NAME": "Notificationcategory", "KEY": "notificationCategory",
      "LABEL": "notificationCategory", "CODE": "notificationCategory", "CHANNEL_ID": "", "FIELD_NAME": "notificationCategory", "BranchCode": "notificationCategory"
    },
    "PERSISTANCY_DROPPED_REASON": {
      "TABLE_NAME": "PersistencyDroppedReason", "KEY": "DroppedReasonID", "LABEL": "DroppedReasonName",
      "CODE": "DroppedReasonID", "CHANNEL_ID": "", "FIELD_NAME": "DroppedReasonID"
    },
    "PERSISTANCY_RESCHEDULE_REASON": {
      "TABLE_NAME": "PersistencyRescheduleReason", "KEY": "RescheduleReasonID", "LABEL": "RescheduleReasonName",
      "CODE": "RescheduleReasonID", "CHANNEL_ID": "", "FIELD_NAME": "RescheduleReasonID"
    },
    "PERSISTANCY_LEAD_SUB_STATUS": {
      "TABLE_NAME": "PersistancyLeadSubStatus", "KEY": "SubStatusID", "LABEL": "SubStatusName",
      "CODE": "SubStatusID", "CHANNEL_ID": "", "FIELD_NAME": "SubStatusID"
    },
    "PERSISTANCY_LEAD_MODE_PAYMENT": {
      "TABLE_NAME": "ModeofPayment", "KEY": "ID", "LABEL": "PaymentName",
      "CODE": "ID", "CHANNEL_ID": "", "FIELD_NAME": "ID"
    },
    "PERSISTANCY_LEAD_PERSISTANCYDEFERREDREASONS": {
      "TABLE_NAME": "PersistencyDeferredReasons", "KEY": "DeferredReasonsID", "LABEL": "DeferredReasonsName",
      "CODE": "DeferredReasonsID", "CHANNEL_ID": "", "FIELD_NAME": "DeferredReasonsID"
    },
    "PERSISTANCY_LEAD_PERSISTANCWILLNOTPAYREASONS": {
      "TABLE_NAME": "PersistencyWillNotPayReasons", "KEY": "WillNotPayReasonsID", "LABEL": "WillNotPayReasonsName",
      "CODE": "WillNotPayReasonsID", "CHANNEL_ID": "", "FIELD_NAME": "WillNotPayReasonsID"
    },
    "PERSISTANCY_DROPPED_REASON": {
      "TABLE_NAME": "PersistencyDroppedReason", "KEY": "DroppedReasonID", "LABEL": "DroppedReasonName",
      "CODE": "DroppedReasonID", "CHANNEL_ID": "", "FIELD_NAME": "DroppedReasonID"
    },
    "PRIORITY": { "TABLE_NAME": "Priority", "KEY": "PriorityId", "LABEL": "PriorityName", "CODE": "PriorityId", "CHANNEL_ID": "", "FIELD_NAME": "PriorityId" },
    "GCLACTIVITYTYPE": { "TABLE_NAME": "GclActivitytype", "KEY": "GCLActivitytypeID", "LABEL": "TypeName", "CODE": "GCLActivitytypeID", "CHANNEL_ID": "", "FIELD_NAME": "GCLActivitytypeID" },
    "PAYMENT_TYPE": {
      "TABLE_NAME": "PaymentType", "KEY": "ID", "LABEL": "Name",
      "CODE": "ShortCode", "CHANNEL_ID": ""
    },
    "PAYMENT_SUB_TYPE": {
      "TABLE_NAME": "PaymentSubType", "KEY": "ID", "LABEL": "Name",
      "CODE": "ShortCode", "CHANNEL_ID": ""
    },
    "CHECK_LIST_TYPE": {
      "TABLE_NAME": "CheckListType", "KEY": "CheckListType", "LABEL": "CheckListName",
      "CODE": "CheckListType", "CHANNEL_ID": ""
    },
    "CHECK_LIST_STATUS": {
      "TABLE_NAME": "CheckListStatus", "KEY": "CheckListStatus", "LABEL": "CheckListStatus",
      "CODE": "CheckListStatus", "CHANNEL_ID": ""
    },

    "LOANTYPE": { "TABLE_NAME": "LoanType", "KEY": "ID", "LABEL": "Name", "CODE": "ID", "CHANNEL_ID": "", "FIELD_NAME": "LoanType" },
    "GCL_CALL_DURATION": { "TABLE_NAME": "GCLCallDuration", "KEY": "ID", "LABEL": "Name", "CODE": "ID", "CHANNEL_ID": "", "FIELD_NAME": "GCLCallDuration" },
    "BROCA_TOPICS_COVERED": { "TABLE_NAME": "BrocaTopicsCovered", "KEY": "TopicsCoveredId", "LABEL": "TopicsCoveredName", "CODE": "TopicsCoveredCode", "CHANNEL_ID": "" },
    "BROCA_TRAINNIG_ATTENDEE": { "TABLE_NAME": "BrocaTrainingAttendee", "KEY": "TrainingAttendeeId", "LABEL": "TrainingAttendeeName", "CODE": "TrainingAttendeeCode", "CHANNEL_ID": "" },
    "BROCA_DESIGNATION": { "TABLE_NAME": "BrocaDesignation", "KEY": "DesignationId", "LABEL": "DesignationName", "CODE": "DesignationCode", "CHANNEL_ID": "" },
    "BROCA_BFDACTIVITY": { "TABLE_NAME": "BrocaBFDActivity", "KEY": "BFDActivityId", "LABEL": "BFDActivityName", "CODE": "BFDActivityCode", "CHANNEL_ID": "" },


    "BROCA_ACTIVITY_PLANNED": { "TABLE_NAME": "BrocaActivityPlanned", "KEY": "ActivityPlannedId", "LABEL": "ActivityPlannedName", "CODE": "ActivityPlannedCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaActivityPlanned" },
    "BROCA_TRAINING_TOPIC": { "TABLE_NAME": "BrocaTrainingTopic", "KEY": "TrainingTopicName", "LABEL": "TrainingTopicName", "CODE": "TrainingTopicCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaTrainingTopic" },
    "BROCA_JOINT_CALL_ACTIVITY": { "TABLE_NAME": "BrocaJointCallActivity", "KEY": "JointCallActivityId", "LABEL": "JointCallActivityName", "CODE": "JointCallActivityCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaJointCallActivity" },
    "BROCA_JOINT_CALL_ACTIVITY_TYPE": { "TABLE_NAME": "BrocaJointCallActivityType", "KEY": "JointCallActivityTypeId", "LABEL": "JointCallActivityTypeName", "CODE": "JointCallActivityTypeCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaJointCallActivityType" },
    "BROCA_CAMPAIGNING_DONE": { "TABLE_NAME": "BrocaCampaigningDone", "KEY": "CampaigningDoneId", "LABEL": "CampaigningDoneName", "CODE": "CampaigningDoneCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaCampaigningDone" },
    "BROCA_LAST_ASSIGNMENTOF": { "TABLE_NAME": "BrocaLastAssignmentOf", "KEY": "LastAssignmentOfId", "LABEL": "LastAssignmentOfName", "CODE": "LastAssignmentOfCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaLastAssignmentOf" },
    "BROCA_MEETING_PLANNED_WITH": { "TABLE_NAME": "BrocaMeetingPlannedWith", "KEY": "MeetingPlannedWithId", "LABEL": "MeetingPlannedWithName", "CODE": "MeetingPlannedWithCode", "CHANNEL_ID": "", "FIELD_NAME": "BrocaMeetingPlannedWith" },
    "MSME_LOAN_TYPE": { "TABLE_NAME": "MSMELoanType", "KEY": "ID", "LABEL": "Name", "CODE": "ShortCode", "CHANNEL_ID": "", "FIELD_NAME": "MSMELoanType" },

  }
}
// CallBackReason
export const LEAD_PRODUCT = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "IndiaFirst Happy India Plan", "label": "IndiaFirst Happy India Plan", "value": "IndiaFirst Happy India Plan" },
  { "key": "IndiaFirst Life Plan", "label": "IndiaFirst Life Plan", "value": "IndiaFirst Life Plan" },
  { "key": "IndiaFirst Smart Save Plan", "label": "IndiaFirst Smart Save Plan", "value": "IndiaFirst Smart Save Plan" },
  { "key": "IndiaFirst Simple Benifit Plan", "label": "IndiaFirst Simple Benifit Plan", "value": "IndiaFirst Simple Benifit Plan" },
  { "key": "IndiaFirst Guaranteed Retirement plan", "label": "IndiaFirst Guaranteed Retirement plan", "value": "IndiaFirst Guaranteed Retirement plan" },
  { "key": "IndiaFirst Immediate Annuity Plan", "label": "IndiaFirst Immediate Annuity Plan", "value": "IndiaFirst Immediate Annuity Plan" },
  { "key": "IndiaFirst CashBack plan", "label": "IndiaFirst CashBack plan", "value": "IndiaFirst CashBack plan" },
  { "key": "IndiaFirst Mahajeevan plan", "label": "IndiaFirst Mahajeevan plan", "value": "IndiaFirst Mahajeevan plan" },
  { "key": "IndiaFirst Money Balance plan", "label": "IndiaFirst Money Balance plan", "value": "IndiaFirst Money Balance plan" },
  { "key": "IndiaFirst Wealth Maximizer", "label": "IndiaFirst Wealth Maximizer", "value": "IndiaFirst Wealth Maximizer" },
  { "key": "IndiaFirst Life Smart Pay Plan", "label": "IndiaFirst Life Smart Pay Plan", "value": "IndiaFirst Life Smart Pay Plan" },
  { "key": "IndiaFirst Life Little Champ", "label": "IndiaFirst Life Little Champ", "value": "IndiaFirst Life Little Champ" },
  { "key": "IndiaFirst EasyLife", "label": "IndiaFirst EasyLife", "value": "IndiaFirst EasyLife" },
  { "key": "IndiaFirst eterm Plus Plan", "label": "IndiaFirst eterm Plus Plan", "value": "IndiaFirst eterm Plus Plan" },
  { "key": "IndiaFirst Life Guaranteed Benefit Plan", "label": "IndiaFirst Life Guaranteed Benefit Plan", "value": "IndiaFirst Life Guaranteed Benefit Plan" },
  { "key": "Guaranteed Protection Plan - Life Option (Limited Premium)", "label": "Guaranteed Protection Plan - Life Option (Limited Premium)", "value": "Guaranteed Protection Plan - Life Option (Limited Premium)" },
  { "key": "Guaranteed Protection Plan - Life Option (Single Premium)", "label": "Guaranteed Protection Plan - Life Option (Single Premium)", "value": "Guaranteed Protection Plan - Life Option (Single Premium)" },
  { "key": "Guaranteed Monthly Income Plan", "label": "Guaranteed Monthly Income Plan", "value": "Guaranteed Monthly Income Plan" }
];

export const LEAD_STATUS = [
  { "key": "Select", "label": "Select", "value": 0 },
  { "key": "hot", "label": "HOT", "value": 1 },
  { "key": "warm", "label": "WARM", "value": 2 },
  { "key": "cold", "label": "COLD", "value": 3 }
];

export const LEAD_TYPE = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "HOT", "label": "HOT", "value": "HOT" },
  { "key": "WARM", "label": "WARM", "value": "WARM" },
];

export const LEAD_ACTIVITY_ID = [
  { "key": "Select", "label": "Select", "value": "" }
];

export const LEAD_SP_TYPE = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Staff", "label": "Staff", "value": "Staff" },
  { "key": "Srm", "label": "Srm", "value": "Srm" },
  { "key": "Wealth executive", "label": "Wealth executive", "value": "Wealth executive" }
];

export const LEAD_BRANCH_TYPE = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "branch name", "label": "branch name", "value": "branch name" }
]

export const LEAD_LG = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "lg name", "label": "lg name", "value": "lg name" }
];

export const LEAD_SP = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "generator name", "label": "generator name", "value": "generator name" },
];

export const LEAD_HAS_MEETING = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "yes", "label": "Yes", "value": true },
  { "key": "no", "label": "No", "value": false },
];
export const FINANICAL_BG = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "yes", "label": "Yes", "value": 1 },
  { "key": "no", "label": "No", "value": 0 },
];

export const LEAD_SOURCE = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "Self", "label": "Self", "value": "Self" },
  { "key": "LG", "label": "LG", "value": "LG" },
  { "key": "SRM", "label": "SRM", "value": "SRM" },
  { "key": "BM", "label": "BM", "value": "BM" },
  { "key": "Other", "label": "Other", "value": "Other" },
];

export const GENDER = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "male", "label": "Male", "value": "Male" },
  { "key": "female", "label": "Female", "value": "Female" },

];

export const restrictPastDate = moment(new Date()).format('YYYY-MM-DD');
export const restrictPastDateTime = new Date().toISOString().slice(0, 16);

export const BANCA_BDM_ACTIVITY = ['', '1', '2', '3', '4', '5', '6','69']; // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
export const BANCA_BDM_ACTIVITY_FILTER = ['', '1', '2', '3', '4', '5', '6', '26', '27', '29', '30','69']; // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
export const BANCA_ABH_ACTIVITY = ['', '1', '2', '3', '4', '5', '6', '25','69']; // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
export const BANCA_ABH_SMA_ACTIVITY = ['', '26', '27', '28', '29', '30', '31']
export const AGENCY_ACTIVITY = ['', '12', '13', '14', '15', '16'];
export const BANCA_FPH_ACTIVITY = ['', '1', '2', '3', '4', '25', '6','69'];
export const BANCA_RBH_SMA_ACTIVITY = ['', '38', '39', '40', '41', '42', '43', '44']
export const GCL_BDM_ACTIVITY_FILTER = ['', '19', '20', '21', '22', '23', '24'];
export const MSME_BDM_ACTIVITY_FILTER = ['', '56', '57', '58', '59', '60', '61', '62'];
export const BANCA_RBH_SMA_ACTIVITY_NEW = ['', '75', '76', '77', '78', '79', '80'];

export const FORMSTATUSID = {
  "NEW": 1,
  "CALLBACK": 2,
  "RESCHEDULE": 3,
  "FOLLOWUP": 4,
  "DROPPED": 5,
  "CONVERTED": 6,
  "MEETINGSCHEDULE": 7,
  "APPLICATION_CREATED": 8,
  "REASSIGN": 9,
  "INTRESTED": 10,
  "DRAFTED": 11,
  "PREMIUM_COLLECTED": 14,
  "LOAN_SANCTION_STAGE": 17,
  "LOAN_PRE-SANCTION_STAGE": 15,
  "LOAN_DISBURSEMENT_STAGE": 19,
  "LOAN_NOT_PRE-SANCTION_STAGE": 16,
  "LOAN_NOT_SANCTION_STAGE": 18,
  "MEMBER_FORM_COLLECTED": 20,
  "MEDICALSCHEDULED": 24,
  "MEDICAL_COMPLETED": 21,
  "COI_ISSUED": 22,
  "ASSIGN": 23,
  "CONNECTED": 26,
  "NOT_CONNECTED": 27,
}

export const ACTIVITYSTATUSID = {
  "ACTIVITY_PLANNED": 1,
  "ACTIVITY_COMPLETED": 2,
  "ACTIVITY_RESCHEDULED": 3,
  "ACTIVITY_CANCELLED": 4,
  "ACTIVITY_AUTO_CANCELLED": 5,
  "ACTIVITY_DRAFTED": 8,
  "ACTIVITY_BACKLOG": 9
}

export const ACTIVITYLASTDATE = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "thisweek", "label": "This Week", "value": datedata('thisweek') },
  { "key": "7days", "label": "Last 7 Days", "value": datedata('7days') },
  { "key": "15days", "label": "Last 15 Days", "value": datedata("15days") },
  { "key": "30", "label": "Last 30 Days", "value": datedata('30days') },
  { "key": "thismonth", "label": "This Month", "value": datedata('thismonth') },
  { "key": "lastmonth", "label": "Last Month", "value": datedata('lastmonth') },
  { "key": "duration", "label": "Custom", "value": "duration" }
];


export const USER_TYPE = [
  { "key": "self", "label": "Self", "value": "self" },
  { "key": "team", "label": "Team", "value": "team" },
]

export const ADMIN_HIERARCHY_TYPE = [
  {
    "key": "Name", "label": "Name/Code", "name": "Name", "value": "1", "id": "Name",
  },
  {
    "key": "Hierarchy", "label": "Hierarchy", "name": "Hierarchy", "value": "2", "id": "Hierarchy",
  },
]
export const ADMIN_HIERARCHY_TYPE_ONLY = [
  {
    "key": "Hierarchy", "label": "Hierarchy", "name": "Hierarchy", "value": "2", "id": "Hierarchy",
  },
]

export const BDM_USER_TYPE = [
  { "key": "all", "label": "All", "value": "" },
]

export const JOINT_CALL_DURATION = [
  { "key": "Select", "label": "Select", "value": "" },
  { "key": "15mins", "label": "15 mins", "value": 1 },
  { "key": "30mins", "label": "30 mins", "value": 2 },
  { "key": "60mins", "label": "60 mins", "value": 3 },
  { "key": "90mins", "label": "90 mins", "value": 4 },
  { "key": "120mins", "label": "120 mins", "value": 5 },

]
export const ALLOWED_FUTURE_DAYS = 31
export const ALLOWED_CHECK_IN_LEAD = ["Interested", "Dropped", "Premium Collected"]
export const ALLOWED_CHECK_IN_ACTIVITY = ["Activity Completed", "Activity Cancelled"];
export const FINISH_DAY_ACTIVITY = ["Finish Day Activity"];
export const CIRCULAR_FOR_SALES_ENABLEMENT_ABH_CHECK = [26, 29, 31];
export const CIRCULAR_FOR_SALES_ENABLEMENT_BROKA_ABH_CHECK = [54, 55];
export const CIRCULAR_FOR_SALES_ENABLEMENT_BDM_CHECK = [26, 27, 28, 29, 30, 31];

export const STATE_HEAD = 'STATE_HEAD'
export const DISTRICT_MANAGER = 'DISTRICT_MANAGER';
export const STATE_MANAGER = 'STATE_MANAGER';
export const VLE_RAP = 'VLE_RAP';
export const RAP_VLE = 'RAP_VLE';
export const LEAD_ACTION_MAPPING = {
  LEAD_STATUS_ID_1: {
    leadStatusId: 1,
    actions: [
      {
        CurrentLeadStatusID: 1,
        ISActionable: true,
        LeadActionCode: "CALLBACK",
        LeadActionName: "Call Back",
        OptionMapID: 2,
      },
      {
        CurrentLeadStatusID: 1,
        ISActionable: true,
        LeadActionCode: "MEETINGSCHEDULE",
        LeadActionName: "Meeting Scheduled",
        OptionMapID: 7,
      },
    ],
  },
  LEAD_STATUS_ID_2: {
    leadStatusId: 2,
    actions: [
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 2,
        LeadActionName: "Call Back",
        LeadActionCode: "CALLBACK",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 3,
        LeadActionName: "Reschedule Meeting",
        LeadActionCode: "RESCHEDULE",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 4,
        LeadActionName: "Follow-Up Meeting",
        LeadActionCode: "FOLLOWUP",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 10,
        LeadActionName: "Interested",
        LeadActionCode: "INTRESTED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 5,
        LeadActionName: "Dropped",
        LeadActionCode: "DROPPED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 6,
        LeadActionName: "Converted",
        LeadActionCode: "CONVERTED",
        ISActionable: false,
      },
      {
        CurrentLeadStatusID: 2,
        OptionMapID: 8,
        LeadActionName: "Application Created",
        LeadActionCode: "APPLICATION_CREATED",
        ISActionable: false,
      },
    ],
  },
  LEAD_STATUS_ID_3: {
    leadStatusId: 3,
    actions: [
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 2,
        LeadActionName: "Call Back",
        LeadActionCode: "CALLBACK",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 3,
        LeadActionName: "Reschedule Meeting",
        LeadActionCode: "RESCHEDULE",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 4,
        LeadActionName: "Follow-Up Meeting",
        LeadActionCode: "FOLLOWUP",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 10,
        LeadActionName: "Interested",
        LeadActionCode: "INTRESTED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 5,
        LeadActionName: "Dropped",
        LeadActionCode: "DROPPED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 6,
        LeadActionName: "Converted",
        LeadActionCode: "CONVERTED",
        ISActionable: false,
      },
      {
        CurrentLeadStatusID: 3,
        OptionMapID: 8,
        LeadActionName: "Application Created",
        LeadActionCode: "APPLICATION_CREATED",
        ISActionable: false,
      },
    ],
  },
  LEAD_STATUS_ID_4: {
    leadStatusId: 4,
    actions: [
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 2,
        LeadActionName: "Call Back",
        LeadActionCode: "CALLBACK",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 3,
        LeadActionName: "Reschedule Meeting",
        LeadActionCode: "RESCHEDULE",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 4,
        LeadActionName: "Follow-Up Meeting",
        LeadActionCode: "FOLLOWUP",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 10,
        LeadActionName: "Interested",
        LeadActionCode: "INTRESTED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 5,
        LeadActionName: "Dropped",
        LeadActionCode: "DROPPED",
        ISActionable: true,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 6,
        LeadActionName: "Converted",
        LeadActionCode: "CONVERTED",
        ISActionable: false,
      },
      {
        CurrentLeadStatusID: 4,
        OptionMapID: 8,
        LeadActionName: "Application Created",
        LeadActionCode: "APPLICATION_CREATED",
        ISActionable: false,
      },
    ],
  },
  LEAD_STATUS_ID_5: {
    leadStatusId: 5,
    actions: [],
  },
};
//Form COnstants
export const CUSTOM_FIELDSET = "custom-fieldset"
export const DATE_MONTH_YEAR_FORMAT = "dd/MM/yyyy"

//Form Types

export const DATETIME_LOCAL = "datetime-local"
//FormLabels
export const DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE = "Do You Want to Claim Travel Expense?"
export const MODE_OF_TRANSPORT = "Mode of Transport"
export const VEHICLE_TYPE = "Select Vehicle Type"
export const TRAVEL_EXPENSE = "Travel Expense"
export const PLEASE_UPLOAD_BILL_COPY = "Please Upload Bill Copy"
export const UPLOAD_BILL_PHOTO = "Upload bill photo"
//Form Validation Messages

export const TRAVEL_EXPENSE_IS_REQUIRED = "Travel expense is required."
export const MODE_OF_TRANSPORT_IS_REQUIRED = "mode of Trasport is required."
export const THIS_IS_REQUIRED = "This is required."
export const MAXIMUM_FOUR_DIGITS_ALLOWED = "Maximum 4 digits allowed"

//FileTypes

export const IMAGE_JPEG = "image/jpeg"
export const APPLICATION_PDF = "application/pdf"

//Date Formats

//Formvalues
//updatelead
export const ACTIVE_BOX_SHADOW = "0px 0px 1px 2px #fffc35"
export const BORDER_ONE_PIXEL_SOLID_GREY = "1px solid #ddd"
export const FA_FA_MAP_MARKER = "fa fa-map-marker"



