import Dynamicform from 'f1-dynamic-form-react';
import React, { Component, Suspense,lazy } from 'react'
import Loader from '../../../components/loader/loader';
import MessageBar from '../../../components/messageBar/messageBar';
import Footer from '../../../layouts/footer/footer';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux"
import {
    getCallBackReasonList, getDroppedReasonList,
    getFollowupReasonList, getLeadListById, getRescheduleReasonList,
    getTeamList, getVehicleTypeList, ModeofTransportList, UpdateLeadForm, validateMeetingDateStatus, validateIsCheckin,
    UpdatePersistancyLeadForm, UpdateCampaignLeadForm,
    getPersistancyLeadListById, validateMeetingDateStatusPersistancy, getCampaginLeadListById,getPersistancyLeadSubStatusList,
    getPersistancyLeadModeOfPaymentList,getPersistencyDeferredReasonsList,getPersistencyWillNotPayReasonsList
} from '../../../redux-reducer/reducer';
import { activateLoader, deActivateLoader, uploadFileToBlob, activateErrorMessageBar ,setCalculatorUsed,setpremiumCalculated,setIntrestedLeadDob} from '../../../redux/actions';
import { customValidators, getForm, getFormTitle, handleDropdownSelection } from './updateLeadForm';
import {
    CALLBACK, checkInConfig, CONFIGS, DROPPED,
    FOLLOWUP, FORMSTATUSID, FOUR_WHEELER, INTRESTED,
    MEETINGSCHEDULE, PUBLIC_TRANSPORT, REASSIGN, RESCHEDULE,
    ROLE_BDM, SELF_OWNED_TRANSPORT, TWO_WHEELER,
    PREMIUMCOLLECTED, LEAD, PERSISTANCY, CAMPAIGNLEAD,
    CONNECTED,NOT_CONNECTED
} from '../../../utils/config';
import { LEADS_PATH, PERSISTANCY_LEAD_PATH, CAMPAIGN_LEAD_PATH } from '../../../route/routePath';
import moment from 'moment';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { setBreadcrumbs } from "../../../redux/actions/common"
import "./updateLead.scss"
import { getLeadOffline, updateLeadOffline } from '../../../offlineManager/leadOfflineManager';
// import { format } from 'date-fns';
import { checkDateIsValid, checkDateIsValidSameOrAfter } from "../../../utils/validators";
import { minusMinuteFromDate, userData } from '../../../utils/utils';
import Progress from '../../../Progress';
const  CalculatorPopUp = lazy(() => import("./calculatorPopUp"));
const _formatDate = "YYYY-MM-DDTHH:mm";
const createformat = "YYYY-MM-DD HH:mm:ss";
const array1 =  ["Select","Already Paid","Will pay in current month","Will pay in subsequent month", "Deferred", "Will not pay"]
const array2 =  ["Select","Customer busy","Not traceable"]
class updateLead extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.leadType = this.props.match.params.leadType;
        if (this.props.match.params.id) {
            if (this.leadType === LEAD) {
                this.props.getLeadListById(this.id)
            } else if (this.leadType === PERSISTANCY) {
                this.props.getPersistancyLeadListById(this.id);
            } else {
                this.props.getCampaginLeadListById(this.id);
            }
        }
        this.userData = JSON.parse(localStorage.getItem('persist:root'));
        this.userId = userData();
        this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;
        this.roleId = JSON.parse(this.userData.userReducer).profileInfo.role;
        this.formBuilder = this.props.match.params.updateAction
        this.calculatorUsed = this.props.masterDataState.isCalculatorUsed

        this.channelId = this.props.userState.profileInfo.channelid;
        this.dyFormRef = React.createRef();
        this.state = {
            defaultFormVal: {
                id: 1,
                MeetingStartDateTime: moment(new Date()).format(_formatDate),
                MeetingEndDateTime: moment(new Date()).add(30, "m")
                    .format(_formatDate),
                IsClaimRequired: false,
            },
            autoCompleteItems: {
                AssignedTo: []
            },
            current: {
                id: 1
            },
            formData: getForm(this.formBuilder),
            extErrors: {},
            uploadedFile: '',
            uploadedDGHFile: '',
            startMeetingDate: moment(new Date()).format(_formatDate),
            endMeetingDate: moment(new Date()).add(30, "m").format(_formatDate),
            leadData: null,
            TravelExpense: '',
            isPopUpModelShow:false,
            // isCalculatorUsed:false,
            isSubmitbuttonDisable: false,
        }
    }

    loadBreadCrumbs = () => {
        let label;
        if (this.leadType === LEAD) {
            label = "Lead";
        } else if (this.leadType === PERSISTANCY) {
            label = "Persistency Lead";
        } else {
            label = "Campaign Lead";
        }
        const breadcrumbs = [
            { name: label, url: `/app/${this.leadType}/all` },
            { name: getFormTitle(this.formBuilder), url: '#' },
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }

    getDropdownData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr?.map((option) => {
                return { key: option[key], label: option[label], value: option[value] }
            })
        }
        arr.unshift({ "key": "select", "label": "select", "value": "" })
        return arr;
    }

    renderRadioIcons = (radioOption) => {
        let icon = ""
        if (radioOption.key === PUBLIC_TRANSPORT) {
            icon = <DirectionsBusIcon></DirectionsBusIcon>
        }
        else if (radioOption.key === SELF_OWNED_TRANSPORT) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === TWO_WHEELER) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === FOUR_WHEELER) {
            icon = <DirectionsCarIcon></DirectionsCarIcon>
        }
        return icon
    }


    getRadioData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { key: option[key], label: option[label], value: option[value] }
            })
        }
        return arr;
    }
    componentWillMount() {
        this.setState({
            autoCompleteItems: this.props.updateLeadData?.AssignedTo
        })
    }


    initFormData = (formFields, actionType, role = ROLE_BDM) => {
       
        let formData = [...formFields]
        const { getLeadData, getPersistancyLeadData, getCampaginLeadData } = this.props.getLeadData;
        const { CallBackReason, FollowupReason, droppedreason, RescheduleReason,
            PersistencyDroppedReason, PersistencyRescheduleReason, PersistencyCallBackReason, PaymentType,PersistancyLeadSubStatus,ModeofPayment,PersistencyDeferredReasons,PersistencyWillNotPayReasons } = this.props.masterDataState
        const { meeting_value } = this.props.updateLeadData;
        const ActivitytypeID = getLeadData.ActivitytypeID
        switch (actionType) {
            case CALLBACK:
                this.props.getCallBackReasonList();
                if(this.leadType === PERSISTANCY  && this.channelId == 1 || this.leadType === PERSISTANCY  && this.channelId == 3 ){
                    formData[3].options = PersistencyCallBackReason
                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.type = "date"
                            const futureMonth = moment(new Date()).add(3, 'M');
                            const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD");
                            option.max = futureMonthEnd;
                            option.label = "Expected lead closure date";
                        }
                    })
                    this.setState({
                        defaultFormVal: {
                            MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate),
                            MeetingEndDateTime: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        },
                        endMeetingDate: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                    })

                } else if (this.leadType === PERSISTANCY) {
                    formData[3].options = PersistencyCallBackReason
                    formData[2].hide = true
                    formData[1].type = "datetime-local";
                    formData[1].label = "Callback End Date & Time";
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                            MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate)
                        },
                        endMeetingDate: moment(getPersistancyLeadData.MeetingEndDateTime).format("YYYY-MM-DD"),
                    })
                } else if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD) && this.channelId == 1) {
                    formData[1].type = "date"
                    const futureMonth = moment(new Date()).add(3, 'M');
                    const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD");
                    formData[1].label = "Expected lead closure date";
                    formData[1].max = futureMonthEnd;
                    // formData[1].min = moment(ExpectedLeadClosureDate).format(_formatDate); 
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(this.leadType === CAMPAIGNLEAD ? getCampaginLeadData.ExpectedLeadClosureDate : getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        },
                        endMeetingDate: moment(this.leadType === CAMPAIGNLEAD ? getCampaginLeadData.ExpectedLeadClosureDate : getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                    })
                    formData[3].options = CallBackReason;
                } else {
                    formData[3].options = CallBackReason;
                    formData[2].hide = true
                }
                break;
            case RESCHEDULE:
                this.props.getRescheduleReasonList();
                if (this.leadType === PERSISTANCY) {
                    formData[4].options = PersistencyRescheduleReason
                    formData[2].hide = true

                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.type = "datetime-local"
                            option.label = "Reschedule Meeting End Date & Time";
                        }
                    })
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                            MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate)
                        },
                        endMeetingDate: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                    })

                } else {
                    formData[4].options = RescheduleReason
                }
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY && this.channelId == 3) {
                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            // option.max = moment(endMeetDate).format("YYYY-MM-DDTHH:mm");
                            // option.min = startDate;
                            option.type = "date"
                            const futureMonth = moment(new Date()).add(3, 'M');
                            const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD");
                            option.max = futureMonthEnd;
                            option.label = "Expected lead closure date";
                        }
                    })

                    if (this.leadType === CAMPAIGNLEAD) {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getCampaginLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })

                    } else if (this.leadType === PERSISTANCY){
                        formData[2].hide = false
                        formData[4].options = PersistencyRescheduleReason
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    } else {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    }


                } else {
                    formData[2].hide = true;
                }
                formData[7].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[8].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                const meeting = new Object();
                if (this.leadType === LEAD) {
                    if (this.channelId == 1) {
                        meeting.MeetingStartDateTime = moment(getLeadData.MeetingStartDateTime).format(_formatDate);
                        meeting.MeetingEndDateTime = moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD");
                    } else {
                        meeting.MeetingStartDateTime = moment(getLeadData.MeetingStartDateTime).format("YYYY-MM-DD HH:mm a");
                        meeting.MeetingEndDateTime = moment(getLeadData.MeetingEndDateTime).format("YYYY-MM-DD HH:mm a");
                    }
                } else if (this.leadType === CAMPAIGNLEAD) {

                    if (this.channelId == 1) {
                        meeting.MeetingStartDateTime = moment(getCampaginLeadData.MeetingStartDateTime).format(_formatDate);
                        meeting.MeetingEndDateTime = moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD");
                    } else {
                        meeting.MeetingStartDateTime = moment(getCampaginLeadData.MeetingStartDateTime).format("YYYY-MM-DD HH:mm a");
                        meeting.MeetingEndDateTime = moment(getCampaginLeadData.MeetingEndDateTime).format("YYYY-MM-DD HH:mm a");
                    }
                }  else if (this.leadType === PERSISTANCY) {
                    if (this.channelId == 1 || this.channelId == 3) {
                        meeting.MeetingStartDateTime = moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate);
                        meeting.MeetingEndDateTime = moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD");
                    } else {
                        meeting.MeetingStartDateTime = moment(getPersistancyLeadData.MeetingStartDateTime).format("YYYY-MM-DD HH:mm a");
                        meeting.MeetingEndDateTime = moment(getPersistancyLeadData.MeetingEndDateTime).format("YYYY-MM-DD HH:mm a");
                    }
                } else {
                    meeting.MeetingDateTime = moment(getPersistancyLeadData.MeetingDateTime).format("YYYY-MM-DDTHH:mm:ss");
                }
                this.setState({
                    defaultFormVal: meeting
                })
                formData.find((val, index) => {
                    if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                        formData[index].hide = true;
                        formData[index].validators = "";
                    }
                })
                break;
            case DROPPED:
                this.props.getDroppedReasonList()
                if (this.leadType === PERSISTANCY) {
                    formData[0].options = PersistencyDroppedReason
                } else {
                    if (this.channelId === 21) {
                        const BrocaReason = ['', '1', '2', '3', '4', '5', '6']
                        const BrocaDroppedList = droppedreason?.filter((val) => {
                            return BrocaReason.includes(val.value)
                        })
                        formData[0].options = BrocaDroppedList
                    } else {
                        formData[0].options = droppedreason
                    }
                }
                formData[3].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[4].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                // formData[3].hide = true;
                // formData[4].hide = true;
                // formData[5].hide = true;
                // formData[6].hide = true;
                formData.find((val, index) => {
                    if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                        formData[index].hide = true;
                        formData[index].validators = "";
                    }
                })
                break;
            case INTRESTED:
                if( ActivitytypeID === 69){
                    formData[1].validators = [
                        { name: "required", value: true, validationMessage: "Goal selection is required"},
                      ]
                } else {
                    formData[1].validators = []
                }
               
                formData[2].hide = true
                formData[3].hide = true
                formData[1].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.GoalType.TABLE_NAME]
                formData[5].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[6].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                formData.find((val, index) => {
                    if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                        formData[index].hide = true;
                        formData[index].validators = "";
                    }
                })
                break;
            case MEETINGSCHEDULE:
                formData[0].options = this.getDropdownData(meeting_value, 'DroppedReasonID', 'DroppedReasonName', 'DroppedReasonID');
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY && this.channelId == 3) {
                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.type = "date"
                            const futureMonth = moment(new Date()).add(3, 'M');
                            const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD");
                            option.max = futureMonthEnd;
                            option.label = "Expected lead closure date";
                        }
                    })


                    if (this.leadType === CAMPAIGNLEAD) {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getCampaginLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })

                    } else if(this.leadType === PERSISTANCY){
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    } else {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    }
                } else {
                    formData[2].hide = true
                }
                if (this.leadType === PERSISTANCY && this.channelId !== 3 && this.channelId !==1 ) {
                    formData[2].hide = true
                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.type = "datetime-local"
                            option.label = "Meeting End Date & Time";
                        }
                    })
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                            MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate)
                        },
                        endMeetingDate: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                    })

                }
                break;
            case FOLLOWUP:
                formData[0].options = FollowupReason;
                if( ActivitytypeID === 69){
                    formData[4].validators = [
                        { name: "required", value: true, validationMessage: "Goal selection is required"},
                      ]
                } else {
                    formData[4].validators = []
                }
                formData[5].hide = true
                formData[6].hide = true
                formData[4].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.GoalType.TABLE_NAME]
                formData[9].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[10].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY ) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY  && this.channelId == 3)  {
                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            // option.max = moment(endMeetDate).format("YYYY-MM-DDTHH:mm");
                            // option.min = startDate;
                            option.type = "date"
                            const futureMonth = moment(new Date()).add(3, 'M');
                            const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD");
                            option.max = futureMonthEnd;
                            option.label = "Expected lead closure date";
                        }
                    })
                    // console.log(getCampaginLeadData);
                    if (this.leadType === CAMPAIGNLEAD ) {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getCampaginLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getCampaginLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })

                    } else if(this.leadType === PERSISTANCY){
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getPersistancyLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    } else if (this.leadType === LEAD) {
                        this.setState({
                            defaultFormVal: {
                                MeetingStartDateTime: moment(getLeadData.MeetingStartDateTime).format(_formatDate),
                                MeetingEndDateTime: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                            },
                            endMeetingDate: moment(getLeadData.ExpectedLeadClosureDate).format("YYYY-MM-DD"),
                        })
                    }
                } else if (this.leadType === PERSISTANCY) {

                    formData.map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.type = "datetime-local"
                            option.label = "Follow-up Meeting End Date & Time";
                        }
                    })
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                            MeetingStartDateTime: moment(getPersistancyLeadData.MeetingStartDateTime).format(_formatDate)
                        },
                        endMeetingDate: moment(getPersistancyLeadData.MeetingEndDateTime).format(_formatDate),
                    })
                    formData[2].hide = true
                } else {
                    formData[2].hide = true
                }
                formData.find((val, index) => {
                    if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                        formData[index].hide = true;
                        formData[index].validators = "";
                    }
                })

                break;
            case PREMIUMCOLLECTED:
                formData[0].options = PaymentType;
                break;
            case REASSIGN:
                this.props.getTeamList(this.userId)
                break;
            case CONNECTED:
                if (this.leadType === PERSISTANCY) {
                    formData[0].options=PersistancyLeadSubStatus.filter((ele)=>{
                        return array1.includes(ele.label)
                      })
                   // formData[0].options =PersistancyLeadSubStatus;
                    formData[1].options=ModeofPayment;
                    formData[3].options=PersistencyDeferredReasons;
                    //PersistencyWillNotPayReasons
                    formData[1].hide = true;
                    formData[2].hide = true;
                    formData[3].hide = true;
                    formData[4].hide = true;
                    formData[5].hide = true;
                } 
                break;
            case NOT_CONNECTED:
                formData[0].options=PersistancyLeadSubStatus.filter((ele)=>{
                return array2.includes(ele.label)
                })
                break;      
            default:
                break;

        }
        return formData
    }
    fetchLeadOffline = () => {
        const leadId = this.props.match.params.id;
        getLeadOffline(leadId).then((res) => {
            if (res) {
                this.setState({ leadData: res });
            }
        })
    };
    componentDidMount() {
        this.props.deActivateLoader();
        this.loadBreadCrumbs();
        let formData = this.initFormData(this.state.formData, this.formBuilder, this.props.userState.profileInfo['roleId'])
        this.setState({ formData: formData });
        this.fetchLeadOffline();
    }


    reset = (model) => {
        for (const [key, value] of Object.entries(model)) {
            model[key] = ''
        }
        if (this.leadType === LEAD) {
            this.props.history.push(LEADS_PATH)
        }
        if (this.leadType === PERSISTANCY) {
            this.props.history.push(PERSISTANCY_LEAD_PATH)
        }
        if (this.leadType === CAMPAIGNLEAD) {
            this.props.history.push(CAMPAIGN_LEAD_PATH)
        }
        return true;
    }
    onSubmitHandler = (model) => {

        if(this.formBuilder == FOLLOWUP || this.formBuilder == INTRESTED){
            const ActivitytypeID = this.props.getLeadData.getLeadData.ActivitytypeID
            if(!this.props.masterDataState.isCalculatorUsed && ActivitytypeID == 69){
                this.props.activateErrorMessageBar(`Usage of calculator is mandatory`);
                return false;
            }
            if(!this.props.masterDataState.isCalculatorUsed  && ActivitytypeID != 69 && model.GoalId != '' && model.GoalId != undefined && model.GoalId != null){
                this.props.activateErrorMessageBar(`You select calculator so usage of calculator is mandatory`);
                return false;
            }
        }
        
        if(this.formBuilder == CONNECTED){
            if(model.SubStatusID=="1"){
                delete model.errors['ReasonID'];
                delete model.errors['WillPayInCurrentMonthDate'];
                delete model.errors['WillPayInSubsequentMonthDate'];
            }else if(model.SubStatusID=="2"){
                delete model.errors['ModeofPaymentID'];
                delete model.errors['ReasonID'];
                delete model.errors['WillPayInSubsequentMonthDate'];
            }else if(model.SubStatusID=="3"){
                delete model.errors['ModeofPaymentID'];
                delete model.errors['ReasonID'];
                delete model.errors['WillPayInCurrentMonthDate'];
            }else if(model.SubStatusID=="4"){
                delete model.errors['ModeofPaymentID'];
                delete model.errors['WillPayInCurrentMonthDate'];
                delete model.errors['WillPayInCurrentMonthDate'];
            }else if(model.SubStatusID=="5"){
                delete model.errors['ModeofPaymentID'];
                delete model.errors['WillPayInCurrentMonthDate'];
                delete model.errors['WillPayInCurrentMonthDate'];
            }

        }
        if (model.TransportModeID === "2") {
            delete model.errors['TravelExpense'];
        }
        /// add condition
        if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
             this.leadType === PERSISTANCY && this.channelId == 3) {
            let MeetingStartDateTime = moment(model.MeetingStartDateTime).format("YYYY-MM-DD");
            let MeetingEndDateTime = moment(model.MeetingEndDateTime).format("YYYY-MM-DD")
            if (!checkDateIsValidSameOrAfter(MeetingEndDateTime, MeetingStartDateTime)) {
                const extError = { ...this.state.extErrors, "MeetingEndDateTime": "Expected lead closure date should be greater than or equal to the meeting date" }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                return false;
            }
        }

        // if (this.formBuilder == INTRESTED) {
        //     formData["ExpectedLeadClosureDate"] = formData["ExpectedLeadClosureDate"] ? formData["ExpectedLeadClosureDate"] : null;
        // } else {
        //     formData["ExpectedLeadClosureDate"] = formData["MeetingEndDateTime"];
        // }

        if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
            return false;
        }
        let formData;
        if (this.leadType === LEAD) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getLeadData } : { ...this.state.leadData }
        }
        if (this.leadType === PERSISTANCY) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getPersistancyLeadData } : { ...this.state.leadData }
        }
        if (this.leadType === CAMPAIGNLEAD) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getCampaginLeadData } : { ...this.state.leadData }
        }

        // [10,5,8,6]=>(intersted,dropped,converted,Application Created,Premium Collected)

        if ([10, 5, 8, 6, 14].includes(formData.LeadStatusId)) {
            this.props.activateErrorMessageBar(`Lead is already ${formData.LeadStatus},Can not be update`);
            return false;
        }
        formData['Remarks'] = null;
        model.PaymentTypeID === "1" ? formData["PaymentSubType"] = model.PaymentSubType : formData["PaymentSubType"] = "";
        for (let key in model) {
            formData[key] = model[key];
        }

        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['Paymentname'];
        delete formData['id'];
        formData['IsCheckin'] = false;
        if (formData['IsCheckin'] && formData['IsClaimRequired']) {
            this.props.activateErrorMessageBar("Lead is not updated");
            return false;
        }

        formData["CreatedBy"] = parseInt(formData["CreatedBy"]);
        formData["SalutationID"] = formData["SalutationID"] ? formData["SalutationID"] : null;
        formData["ModifiedBy"] = parseInt(this.userId);

        formData['IsLmsSync'] = navigator.onLine;
        formData['LmsleadlastSyncTime'] = moment(new Date()).format(createformat)
        formData['IsBacklog'] = false;
        if (formData.hasOwnProperty('UploadBill')) {
            formData["UploadBill"] = this.state.uploadedFile;
        }
        if (formData.hasOwnProperty('UploadDGHPhoto')) {
            formData["UploadDGHPhoto"] = this.state.uploadedDGHFile;
        }
        formData['LeadStatusId'] = FORMSTATUSID[this.formBuilder]
        formData['LeadStatus'] = this.formBuilder;

        formData['UserId'] = this.userId;
        formData['AssignedTo'] = this.userId;
        formData["ChannelID"] = parseInt(this.channelId);
        formData['EmployeeCode'] = this.employeeCode;

        const validateMeetingStatusForm = [RESCHEDULE, MEETINGSCHEDULE, CALLBACK, FOLLOWUP];
        if (model.IsClaimRequired) {
            formData['TransportID'] = model.TransportModeID;
            formData['TransportModeID'] = parseInt(model.TransportModeID);
            formData['TravelExpense'] = model.TravelExpense;
            formData['TransportMode'] = model.TransportModeID === "1" ? "Public Transport" : "Self Owned Transport";

        }
        //////
        if (validateMeetingStatusForm.includes(this.formBuilder)) {
            if (this.state.startMeetingDate) {
                formData['MeetingStartDateTime'] = this.state.startMeetingDate.replace("T", ' ');
            } else {
                formData["MeetingStartDateTime"] = null;
            }

            if (this.state.endMeetingDate) {
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY && this.channelId == 3) {
                    formData['MeetingEndDateTime'] = this.state.endMeetingDate;
                } else {
                    formData['MeetingEndDateTime'] = minusMinuteFromDate(this.state.endMeetingDate).replace("T", ' ');
                }
            } else {
                formData["MeetingEndDateTime"] = null;
            }
        } else {
            formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"] ? formData["MeetingStartDateTime"] : null;
            formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"] ? formData["MeetingEndDateTime"] : null;
        }

        if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
             this.leadType === PERSISTANCY && this.channelId == 3 ) {
            if (this.formBuilder == INTRESTED) {
                formData["ExpectedLeadClosureDate"] = formData["ExpectedLeadClosureDate"] ? moment(formData['ExpectedLeadClosureDate']).format("YYYY-MM-DD HH:mm:ss"): null;
            } else {
                formData["ExpectedLeadClosureDate"] = formData["MeetingEndDateTime"] ?  moment(formData["MeetingEndDateTime"]).format("YYYY-MM-DD HH:mm:ss") : null;
            }
        }

        if (formData["LeadStatus"] === "DROPPED") {
            formData['MeetingStartDateTime'] = formData['MeetingStartDateTime'] ? moment(formData['MeetingStartDateTime']).format("YYYY-MM-DD HH:mm:ss") : null;
            formData['MeetingEndDateTime'] = formData['MeetingEndDateTime'] ? moment(formData['MeetingEndDateTime']).format("YYYY-MM-DD HH:mm:ss") : null;
        }
        if (this.leadType === PERSISTANCY && this.formBuilder === PREMIUMCOLLECTED) {
            formData['MeetingDateTime'] = formData['MeetingDateTime'] ? moment(formData['MeetingDateTime']).format("YYYY-MM-DD HH:mm:ss") : null;
            formData['DueDate'] = formData['DueDate'] ? moment(formData['DueDate']).format("YYYY-MM-DD HH:mm:ss") : null;
            formData['CreatedDate'] = formData['CreatedDate'] ? moment(formData['DueDate']).format("YYYY-MM-DD HH:mm:ss") : null;
            formData['FundAccountDate'] = formData['FundAccountDate'] ? moment(formData['FundAccountDate']).format("YYYY-MM-DD HH:mm:ss") : null;
            formData['ChequePaymentDate'] = formData['ChequePaymentDate'] ? moment(formData['ChequePaymentDate']).format("YYYY-MM-DD HH:mm:ss") : null;
        }
        this.props.activateLoader();


        if (this.leadType === LEAD) {

          //Sachin M:Changes to assign value leadintrestedDob to DOB
          if (this.formBuilder == INTRESTED) {
            let dateString = this.props.masterDataState.intrestedLeadDob;
             // Regex for the format 1991-01-01 (ISO 8601)
            if(dateString){
                const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
                if (isoFormat.test(dateString)) {
                  formData["leadInterestedDOB"] =
                    moment(dateString).format("DD-MM-YYYY");
                } else {
                  let dateParts = dateString.split("/"); // Split by the '/'
                  let formattedDate = dateParts.join("-"); // Join with '-'
                  formData["leadInterestedDOB"] = formattedDate
                    ? formattedDate
                    : null;
                }
            }
          
            }
            if (validateMeetingStatusForm.includes(this.formBuilder)) {
                if (navigator.onLine) {
                    this.props.validateMeetingDateStatus({
                        startDate: this.state.startMeetingDate,
                        endDate: this.state.endMeetingDate,
                        id: this.userId,// (this.leadType === LEAD) ? this.userId : ,
                        formData: formData,
                        formBuilder: this.formBuilder,
                        history: this.props.history,
                        leadType: 1,
                    })
                    // this method will update the lead in indexDb for offline version
                    updateLeadOffline(formData);
                } else {
                    updateLeadOffline(formData).then((res) => {
                        this.props.history.push(LEADS_PATH);
                    })
                        .catch((err) => {
                            console.log(err);
                            this.props.history.push(LEADS_PATH);
                        })
                }
            } else {
                if (navigator.onLine) {
                    this.props.UpdateLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history })
                } else {
                    // update lead in offline
                    updateLeadOffline().then((res) => {
                        this.props.history.push(LEADS_PATH);
                    })
                        .catch((err) => {
                            console.log(err);
                            this.props.history.push(LEADS_PATH);
                        })
                }
            }
        } else {
            if (validateMeetingStatusForm.includes(this.formBuilder)) {
                if (navigator.onLine) {
                    if (validateMeetingStatusForm.includes(this.formBuilder)) {
                        this.props.validateMeetingDateStatusPersistancy({
                            startDate: this.state.startMeetingDate,
                            endDate: this.state.endMeetingDate,
                            id: this.userId,
                            formData: formData,
                            formBuilder: this.formBuilder,
                            history: this.props.history,
                            leadType: this.leadType,
                        })
                    }
                }
            } else {
                if (navigator.onLine) {
                    if (this.leadType === PERSISTANCY) {
                        this.props.UpdatePersistancyLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history })
                    } else {
                        this.props.UpdateCampaignLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history });
                    }
                }
            }
        }

        if (this.props.updateLeadData.updateLead.responsestatus === true) {
            this.reset(formData);
        }
    }

    onChangeHandler = async (event, key, model) => {
        // this will enable the submit button
    
        if (key === 'UploadBill') {
            this.uploadFile(event, key);
        }
        if (key === 'UploadDGHPhoto') {
            this.uploadFile(event, key);
        }
        const formData = handleDropdownSelection(event, key, this.state.formData, this.props.match.params.updateAction, this.props.masterDataState,
            this.props.updateLeadData, this.props.userState.profileInfo['roleId'])
        const isExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TransportModeID")
        const isVehicleType = formData && formData.length > 0 && formData.find((data) => data.key === "VehicleTypeID")
        const isTravelExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TravelExpense")
        const isUploadBill = formData && formData.length > 0 && formData.find((data) => data.key === "UploadBill")
        if (key === "PaymentSubType" && event.target.value === "1") {
            formData[2].hide = false;
            formData[3].hide = true;
            formData[3].validators = [];
            formData[2].validators = [{ name: "required", value: true, validationMessage: "This is required." },];
        }
        if (key === "PaymentSubType" && event.target.value === "2") {
            formData[3].hide = false;
            formData[2].hide = true;
            formData[2].validators = [];
            formData[3].validators = [{ name: "required", value: true, validationMessage: "This is required." },];
        }
        if(key==="SubStatusID" && this.props.match.params.updateAction==="CONNECTED"){
            if(event.target.value === "1"){
                formData[1].hide = false;
                formData[5].hide = false;
                formData[4].hide = true;
                formData[2].hide = true;
                formData[3].hide = true;
            }else  if(event.target.value === "2"){
                formData[2].hide = false;
                formData[5].hide = false;
                formData[1].hide = true;
                formData[3].hide = true;
                formData[4].hide = true;
                this.setState({
                 defaultFormVal: {
                 WillPayInCurrentMonthDate: moment(new Date()).format(_formatDate),
                },
                })
                   
            }
            else  if(event.target.value === "3"){
                formData[3].hide = false;
                formData[5].hide = false;
                formData[1].hide = true;
                formData[2].hide = true;
                formData[4].hide = true;
                this.setState({
                    defaultFormVal: {
                        WillPayInSubsequentMonthDate: moment(new Date()).format(_formatDate),
                    },
                })
            }
            else  if(event.target.value === "4"){
                formData[4].hide = false;
                formData[4].options = this.props.masterDataState.PersistencyDeferredReasons;
                formData[5].hide = false;
                formData[1].hide = true;
                formData[2].hide = true;
                formData[3].hide = true;
            }
            else  if(event.target.value === "5"){
                formData[4].hide = false;
                formData[5].hide = false;
                formData[4].options = this.props.masterDataState.PersistencyWillNotPayReasons;
                formData[1].hide = true;
                formData[2].hide = true;
                formData[3].hide = true;
            }
           
        }
        if(key==="SubStatusID" && this.props.match.params.updateAction==="NOT_CONNECTED"){
            if(event.target.value === "6"){
                formData[1].hide = false;
            }else{
                formData[1].hide = false;
            }
        }
        this.setState({ formData: formData })

        switch (key) {
            case 'MeetingStartDateTime':
                // const copyformData = this.state.formData
                this.setState({ extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null } })
                delete model.errors['MeetingEndDateTime']

                var startDate = moment(event.target.value).format(_formatDate);
                let endMeetDate;
                let maxendMeetDate;
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY && this.channelId == 3 ) {
                    maxendMeetDate = moment(startDate).add(3, 'M').toString();
                    endMeetDate = moment(event.target.value).format(_formatDate);
                    startDate = moment(event.target.value).format(_formatDate);
                } else {
                    endMeetDate = moment(startDate).add(30, 'm').toString();
                    maxendMeetDate = moment(startDate).add(30, 'm').toString();
                }
                this.state.formData.map((option) => {
                    if (option.key === "MeetingEndDateTime") {
                        option.max = moment(maxendMeetDate).format("YYYY-MM-DDTHH:mm");
                        option.min = startDate;
                    }
                })

                this.setState({ startMeetingDate: startDate.replace("T", ' ') })

                if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
                    this.setState({
                        startMeetingDate: startDate.replace("T", ' '), extErrors: { ...this.state.extErrors, MeetingStartDateTime: "please select future value" }
                    })
                } else {
                    if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                          this.leadType === PERSISTANCY && this.channelId == 3 ) {
                        this.setState({
                            defaultFormVal: {
                                // MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss")
                                MeetingStartDateTime: event.target.value
                            },
                            extErrors: {}
                        })
                    } else {
                        this.setState({
                            defaultFormVal: {
                                MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"),
                                MeetingStartDateTime: event.target.value,
                            },
                            extErrors: {}
                        })
                        this.setState({ endMeetingDate: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' ') });

                    }

                }
                if (this.formBuilder === 'RESCHEDULE') {

                    formData[1].max = moment(maxendMeetDate).endOf('day').format("YYYY-MM-DD kk:mm");
                    formData[1].min = startDate;
                }

                break;
            case 'MeetingEndDateTime':
                const endDate = event.target.value;
                this.setState({ endMeetingDate: endDate.replace("T", ' ') })
                this.setState({ ...this.state, extErrors: {} })
                if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD || this.leadType === PERSISTANCY) && this.channelId == 1 ||
                     this.leadType === PERSISTANCY && this.channelId == 3) {
                    if (!checkDateIsValidSameOrAfter(event.target.value, moment(this.state.defaultFormVal.MeetingStartDateTime).format("YYYY-MM-DD"))) {
                        this.setState({
                            defaultFormVal: {
                                MeetingEndDateTime: moment(endDate).format("YYYY-MM-DD")
                            },
                            extErrors: { ...this.state.extErrors, MeetingEndDateTime: "Expected lead closure date should be greater than or equal to the meeting date" }
                        })
                    } else {
                        this.setState({
                            defaultFormVal: {
                                MeetingEndDateTime: moment(endDate).format("YYYY-MM-DD")
                            }, extErrors: {},
                            endMeetingDate: moment(endDate).format("YYYY-MM-DD"),
                        })
                    }
                } else {
                    if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
                        this.setState({
                            defaultFormVal: {
                                MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            },
                            extErrors: { ...this.state.extErrors, MeetingEndDateTime: "meeting end date should not less than meeting starting date" }
                        })
                    } else {
                        this.setState({
                            defaultFormVal: {
                                MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            }, extErrors: {},
                            endMeetingDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
                        })
                    }
                }
                // if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {

                //     if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD) && this.channelId == 1) {
                //         this.setState({
                //             defaultFormVal: {
                //                 MeetingEndDateTime: moment(endDate).format("YYYY-MM-DD")
                //             },
                //             extErrors: { ...this.state.extErrors, MeetingEndDateTime: "Expected lead closure date should be greater than or equal to the meeting date" }
                //         })
                //     } else {
                //         this.setState({
                //             defaultFormVal: {
                //                 MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                //             },
                //             extErrors: { ...this.state.extErrors, MeetingEndDateTime: "meeting end date should not less than meeting starting date" }
                //         })
                //     }

                // } else {
                //     if ((this.leadType === LEAD || this.leadType === CAMPAIGNLEAD) && this.channelId == 1) {
                //         this.setState({
                //             defaultFormVal: {
                //                 MeetingEndDateTime: moment(endDate).format("YYYY-MM-DD")
                //             }, extErrors: {},
                //             endMeetingDate: moment(endDate).format("YYYY-MM-DD"),
                //         })
                //     } else {
                //         this.setState({
                //             defaultFormVal: {
                //                 MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                //             }, extErrors: {},
                //             endMeetingDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
                //         })
                //     }


                // }

                break;
            case 'IsClaimRequired':
                if (event === true) {
                    this.setState({
                        defaultFormVal: {
                            IsClaimRequired: true
                        }
                    })
                    isExpense.hide = false;
                    let checkIn;
                    if (this.leadType === PERSISTANCY) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: PERSISTANCY, Id: this.props.match.params.id })
                    }
                    if (this.leadType === LEAD) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: LEAD, Id: this.props.match.params.id })
                    }
                    if (this.leadType === CAMPAIGNLEAD) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: "campaignlead", Id: this.props.match.params.id })
                    }
                    const { data } = checkIn

                    if (data) {
                        this.setState({
                            defaultFormVal: {
                                IsClaimRequired: data
                            }
                        })
                        // isExpense.hide = false
                        // // isVehicleType.hide = false
                        // isTravelExpense.hide = false
                        // isUploadBill.hide = false

                        const errorElement = document.querySelectorAll('input[type=radio]');
                        errorElement[0].checked = false;
                        errorElement[1].checked = false;

                        const errorElement1 = document.getElementsByClassName(`activeRadio`);
                        if (errorElement1.length > 0) {
                            errorElement1[0].classList.remove('activeRadio');
                        }



                        isExpense.validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": "This is required."
                            }

                        ]
                    } else {
                        this.setState({
                            defaultFormVal: {
                                IsClaimRequired: false
                            }
                        })
                        isExpense.hide = true
                        // isVehicleType.hide = true
                        isTravelExpense.hide = true
                        isTravelExpense.validators = []
                        isUploadBill.hide = true
                        this.props.activateErrorMessageBar("Please Check-In first to claim the expenses")
                        delete model.errors['UploadBill'];
                    }
                } else {
                    // custom-radio.classList.remove('activeRadio');

                    // errorElement.classList.remove('activeRadio');
                    this.setState({
                        defaultFormVal: {
                            IsClaimRequired: false
                        }
                    })
                    isExpense.hide = true
                    isExpense.checked = false;
                    isVehicleType.hide = true
                    isTravelExpense.hide = true
                    isUploadBill.hide = true
                }

                break;
            case "TravelExpense":
                const expense = event.target.value;
                if (expense < 0) {
                    this.setState({
                        extErrors: { ...this.state.extErrors, TravelExpense: "Travel Expense should be greater than 0" }
                    })
                } else if (expense.length > 0 && expense.length > 4) {
                    this.setState({
                        extErrors: { ...this.state.extErrors, TravelExpense: 'Maximum 4 digits allowed' }
                    })
                } else {
                    this.setState({
                        extErrors: {}
                    })
                }
                break;
            case "TransportModeID":
                const TransportModeID = event.target.value;
                if (TransportModeID === "2") {
                    isExpense.validators = [];
                    delete model.errors['TravelExpense'];
                }
                break;
            case "GoalId":
                                  
                    if(event.target.value !== ''){
                        formData.find((elem,index)=>{
                             if(['EditedCalculatedPremium','CalculatedPremium'].includes(elem.key)){
                                formData[index].hide = true
                             }
                        })
                        this.props.setCalculatorUsed(false)
                        this.props.setpremiumCalculated(0)
                        this.setState({ 
                            ...this.state,
                            isPopUpModelShow: true ,
                            calculatorName:event.target.value,
                          // isCalculatorUsed:false
                        })
                      
                    } else {
                        formData.find((elem,index)=>{
                             if(['EditedCalculatedPremium','CalculatedPremium'].includes(elem.key)){
                                formData[index].hide = true
                             }
                        })
                        this.props.setCalculatorUsed(false)
                        this.props.setpremiumCalculated(0)
                        this.setState({
                            ...this.state,
                            isPopUpModelShow: false ,
                            calculatorName:event.target.value,
                            //  isCalculatorUsed:false
                        })
                    }
               break;
            case "EditedCalculatedPremium":
            this.setState({
                defaultFormVal: {
                    ...this.state.defaultFormVal,
                    EditedCalculatedPremium: event.target.value
                },
            })
               default:
                break;
        }
    }

    uploadFile = async (files, key) => {
        if (navigator.onLine) {
            const fileRes = await this.props.uploadFileToBlob({ 'file': files[0] });
            const { downloadUrl } = fileRes.data
            if (fileRes.status === 200) {
                if (key === "UploadDGHPhoto") {
                    this.setState({ uploadedDGHFile: downloadUrl })
                } else {
                    this.setState({ uploadedFile: downloadUrl })
                }
            }
        } else {
            this.props.activateErrorMessageBar(
                "Cannot upload files in offline mode!"
            );
        }
    }


    removeFile = (e, file, index, key) => {
        this.dyFormRef.removeFile(e, file, index, key);
        this.setState({ uploadedFile: '', uploadedDGHFile: '' })
    }

    renderUploadedFilesItem = (file, index, key) => {
        return (
            <li style={{ borderColor: '#ccc' }} key={index}>
                <span className="file-name">{file.name}</span>
                <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }

    onCancelHandler = () => {
        this.props.history.push(`/app/${this.leadType}/detail/${this.id}`);
    }

    onAutocompleteSelectHandler = (key, value, item) => {
    }

    calculatorSubmit = (payload) => {
       this.props.setCalculatorUsed(true)
       const copyformData = [...this.state.formData]
       copyformData.find((elem,index)=>{
            if(['EditedCalculatedPremium','CalculatedPremium'].includes(elem.key)){
                copyformData[index].hide = false
            }
       })
       const premiumAmount =  this.props.masterDataState.premiumCalculated
       this.setState({
        ...this.state,
        formData:copyformData,
        isPopUpModelShow:false,
        defaultFormVal:{
        ... this.state.defaultFormVal,
        CalculatedPremium:premiumAmount,
        EditedCalculatedPremium:premiumAmount,
        }
       })
    }

    calculatorCancel = (payload) => {
        this.props.setCalculatorUsed(false)
        this.props.setpremiumCalculated(0)
        this.setState({
            isPopUpModelShow:false
        })
    }
    onCheckinHandler = (position, address) => { }

    render() {
        const { getLeadData, getPersistancyLeadData, getCampaginLeadData } = this.props.getLeadData;
        let label;
        if (this.leadType === LEAD) {
            label = `${getLeadData.Salutation ? getLeadData.Salutation : ""} ${getLeadData.FirstName} ${getLeadData.LastName}`;
        }
        if (this.leadType === PERSISTANCY) {
            label = getPersistancyLeadData.CustomerName;
        }
        if (this.leadType === CAMPAIGNLEAD) {
            label = `${getCampaginLeadData.SalutationName ? getCampaginLeadData.SalutationName : ""} ${getCampaginLeadData.FirstName} ${getCampaginLeadData.LastName}`;
        }

        return (
            <div className="mainContainer updateLead">
                <MessageBar />
                <Breadcrumb />
                <span className="formInfo">{label}</span>
                <div className="formBox">
                    <Dynamicform
                        key={this.state?.current?.id}
                        onRef={ref => this.dyFormRef = ref}
                        className="updateTypeDiv"
                        buttonSubmit="Update"
                        defaultValues={this.state.defaultFormVal}
                        model={this.state.formData}
                        onSubmit={(model) => {
                            this.onSubmitHandler(model);
                        }}
                        disableSubmitOnInValid={this.state.isSubmitbuttonDisable}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
                        buttonNormalClick={(id, key, obj) => console.log(id, key, obj)}
                        showAstersikOnRequiredFields={true}
                        fileUploaderFields={['UploadBill', 'UploadDGHPhoto']}
                        renderUploadedFilesItem={this.renderUploadedFilesItem}
                        onAutocompleteSelect={this.onAutocompleteSelectHandler}
                        autoCompleteItems={this.state.autoCompleteItems}
                        extErrors={this.state.extErrors}
                        checkInConfig={checkInConfig}
                        onCheckinHandler={this.onCheckinHandler}
                        extaraActionFormButton={<>
                            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{('Cancel')}</button>
                        </>}
                        renderRadioIcons={(option) => this.renderRadioIcons(option)}
                        customValidators={customValidators}
                    />
                </div>
                <Footer></Footer>
                <Suspense fallback={<Progress/>}>
                <CalculatorPopUp 
                showModalSetup={this.state.isPopUpModelShow} 
                onClose={this.calculatorCancel} 
                calculatorName={this.state.calculatorName}
                onSubmit={this.calculatorSubmit}
                />
                </Suspense>
                {this.props.masterDataState.isActiveLoader && <Loader />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    updateLeadData: state.updateLeadReducer,
    getLeadData: state.leadFormReducer,
    masterDataState: state.commonReducer,
    userState: state.userReducer
})

const mapDispatchToProps = dispatch => ({
    getPersistancyLeadListById: (payload) => dispatch(getPersistancyLeadListById(payload)),
    getLeadListById: (payload) => dispatch(getLeadListById(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    getCallBackReasonList: (payload) => dispatch(getCallBackReasonList(payload)),
    getRescheduleReasonList: (payload) => dispatch(getRescheduleReasonList(payload)),
    ModeofTransportList: (payload) => dispatch(ModeofTransportList(payload)),
    getVehicleTypeList: (payload) => dispatch(getVehicleTypeList(payload)),
    getFollowupReasonList: (payload) => dispatch(getFollowupReasonList(payload)),
    UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
    validateMeetingDateStatus: (payload) => dispatch(validateMeetingDateStatus(payload)),
    validateMeetingDateStatusPersistancy: (payload) => dispatch(validateMeetingDateStatusPersistancy(payload)),
    getTeamList: (props) => dispatch(getTeamList(props)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    getDroppedReasonList: (payload) => dispatch(getDroppedReasonList(payload)),
    validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    UpdatePersistancyLeadForm: (payload) => dispatch(UpdatePersistancyLeadForm(payload)),
    uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
    getCampaginLeadListById: (payload) => dispatch(getCampaginLeadListById(payload)),
    UpdateCampaignLeadForm: (payload) => dispatch(UpdateCampaignLeadForm(payload)),
    setCalculatorUsed: (payload) => dispatch(setCalculatorUsed(payload)),
    setpremiumCalculated: (payload) => dispatch(setpremiumCalculated(payload)),
    setIntrestedLeadDob: (payload) => dispatch(setIntrestedLeadDob(payload)),
    getPersistancyLeadSubStatusList: (payload) => dispatch(getPersistancyLeadSubStatusList(payload)),
    getPersistancyLeadModeOfPaymentList: (payload) => dispatch(getPersistancyLeadModeOfPaymentList(payload)),
    getPersistencyDeferredReasonsList: (payload) => dispatch(getPersistencyDeferredReasonsList(payload)),
    getPersistencyWillNotPayReasonsList: (payload) => dispatch(getPersistencyWillNotPayReasonsList(payload)),
})
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(updateLead));
