import React, { useState } from "react";
import ActivityListing from "./campaignActivityGridListing";
import CSCCampaignActivityListing from "./cscCampaignActivityListing";
import { connect, useSelector } from "react-redux";
import { activateLoader } from "../../../redux/actions/common";
import { useLocation, useParams } from "react-router-dom";

export const CampaignActivityAgencyListing = () => {
  const ChannelId = 3;
  const CAMPAIGN_LEAD_AGENCY = "Campaign Agency Activity Listing";
  const filetype = "campaignactivitiesagency";
  const campaignactivityid = "8";
  const fileName = "CampaignActivitiesAgency_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_AGENCY}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const CampaignActivityBancaListing = () => {
  const ChannelId = 1;
  const CAMPAIGN_LEAD_BANCA = "Campaign Banca Activity Listing";
  const filetype = "campaignactivities";
  const campaignactivityid = "7";
  const fileName = "CampaignActivitiesBanca_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_BANCA}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};
export const CampaignActivityRBHBancaListing = () => {
  const ChannelId = 1;
  const CAMPAIGN_LEAD_BANCA = "Campaign Banca Activity Listing";
  const filetype = "campaignactivities";
  const campaignactivityid = "7";
  const fileName = "CampaignActivitiesBanca_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_BANCA}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const SMAActivityBancaListing = () => {
  const ChannelId = 1;
  const CAMPAIGN_LEAD_BANCA = "SMA Banca Activity Listing";
  const filetype = "campaignactivities";
  const campaignactivityid = "7";
  const fileName = "SMAActivitiesBanca_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_BANCA}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const ActivityCscListing = () => {
  const ChannelId = 2;
  const CAMPAIGN_LEAD_CSC = "CSC Activity Listing";
  const filetype = "campaignactivitiescsc";
  const campaignactivityid = "9";
  const fileName = "CampaignActivitiesCSC_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_CSC}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const ActivityGCLListing = () => {
  const ChannelId = 13;
  const CAMPAIGN_LEAD_GCL = "GCL Activity Listing";
  const filetype = "campaignactivitiesgcl_";
  const campaignactivityid = "13";
  const fileName = "campaignactivitiesgcl_";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_GCL}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const CampaignActivityCSCListing = () => {
  const ChannelId = 2;
  const CAMPAIGN_LEAD_CSC = "Campaign CSC Activity Listing";
  const filetype = "campaignactivitiescsc";
  const campaignactivityid = "9";
  const fileName = "CampaignActivitiesCSC_";
  return (
    <>
      <CSCCampaignActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_CSC}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};

export const CampaignActivityBrocaListing = () => {
  const ChannelId = 21;
  const CAMPAIGN_LEAD_BANCA = "Broca Activity Listing";
  const filetype = "campaignactivities";
  const fileName = "ActivitiesBroca_";
  const campaignactivityid = "7";
  return (
    <>
      <ActivityListing
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_BANCA}
        filetype={filetype}
        campaignactivityid={campaignactivityid}
        fileName={fileName}
      />
    </>
  );
};
const ActivityListingAll = (props) => {
  const location = useLocation()
  let isSMARoute = location.pathname.includes("smaActivities")
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const [channelId] = useState(userState.profileInfo.channelid);
  const ACTIVITY_TITLE = isSMARoute ? "Sales Management Activity List " : "Activity Listing";
  const fileName = isSMARoute ? "SMAActivities_": "Activities_";
  return (
    <>
      <ActivityListing
        ChannelId={channelId}
        Listingtitle={ACTIVITY_TITLE}
        fileName={fileName}
      />
    </>
  );
};

const ActivityListingAllRBHSM = (props) => {
  const location = useLocation()
  let isSMARoute = location.pathname.includes("smaActivities")
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const [channelId] = useState(userState.profileInfo.channelid);
  const ACTIVITY_TITLE = isSMARoute ? "Sales Management Activity List " : "Activity Listing";
  const fileName = isSMARoute ? "SMAActivities_": "Activities_";
  return (
    <>
      <ActivityListing
        ChannelId={channelId}
        Listingtitle={ACTIVITY_TITLE}
        fileName={fileName}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  activateLoader,
})(ActivityListingAll,ActivityListingAllRBHSM);
