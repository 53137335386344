// import { format } from "date-fns";

// const dateFormate = "yyyy-MM-dd";
// const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";

// const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
// const minDate =
//   format(new Date(), dateFormate) + "T" + format(new Date(), "kk:mm");
// const datetimelocal = "datetime-local";

/*  --------------------------------------------------------
            Competed Activity start
    --------------------------------------------------------  */

export const BankaSalesMangementCompletedActivity = [
  {
    key: "SalesManagementIDCompleted",

    type: "select",

    id: "SalesMangementID",

    label: "Sales Management Activity Type",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: "Please select sales management activity type",
      },
    ],

    options: []
  },
];

//------------------- Complete Activity 1 ( Schedule BDM ) ---------------------------------

export const CompletedActivitySchedule_BDM = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "ActionPlanasAgreedWithBDM",
    type: "text",
    id: "ActionPlanasAgreedWithBDM",
    label: "Action plan as agreed with BDM",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter action Plan",
      },
    ],
  },
];

//------------------- Complete Activity 2 ( Branch visit plan ) ---------------------------------

export const CompletedActivityBranchvisitplan = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Branch Visit",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of branch visit",
      },
    ],
    options: [],
  },
  {
    key: "MetBranchManager",
    type: "select",
    id: "MetBranchManager",
    label: "Met Branch Manager",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select an option ",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      {
        key: "Yes",
        label: "Yes",
        value: 1,
      },
      { key: "No", label: "No", value: 0 },
    ],
  },
  {
    key: "Comments",
    type: "text",
    id: "Comments",
    label: "Comments",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter comments",
      },
    ],
  },
  {
    key: "CBIsClaimRequired",
    name: "CBIsClaimRequired",
    type: "CustomSwitch",
    id: "CBIsClaimRequired",
    label: "Do you want to claim travel expense?",
    // validators: [
    //   {
    //     name: "required",
    //     value: false,
    //     validationMessage: "optionSelectValidationMessage",
    //   },
    // ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Complete Activity 3 ( Regional Office meeting ) ---------------------------------

export const CompletedActivityRegionalOfficemeeting = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "DiscussCurrentCampaign",
    type: "text",
    id: "DiscussCurrentCampaign",
    label: "Discuss Current Campaign",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter current campaign",
      },
    ],
  },
  {
    key: "DiscussIfTheRegionisQualifiedforit",
    type: "text",
    id: "DiscussIfTheRegionisQualifiedforit",
    label: "Discuss if the Region is Qualified for it",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter region if the region is qualified",
      },
    ],
  },
  {
    key: "ActionablesDecided",
    type: "text",
    id: "ActionablesDecided",
    label: "Actionables Decided",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter actionables",
      },
    ],
  },
  {
    key: "CROIsClaimRequired",
    name: "CROIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CROIsClaimRequired",
    label: "Do you want to claim travel expense?",
    // validators: [
    //   {
    //     name: "required",
    //     value: false,
    //     validationMessage: "",
    //   },
    // ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];


export const CompletedActivityZonalOfficevisitPlan_RBH = [
  {
    key: "PersonMet",
    type: "text",
    id: "PersonMet",
    label: "Person Met",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter person met details",
      },
      { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
      { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 characters." }
    ],
  },
  {
    key: "Designation",
    type: "text",
    id: "Designation",
    label: "Designation",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter designation",
      },
      { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
      { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 characters." }
    ],
  },
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "DiscussCurrentCampaign",
    type: "text",
    id: "DiscussCurrentCampaign",
    label: "Discuss Current Campaign",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter current campaign",
      },
    ],
  },
  {
    key: "DiscussIfTheRegionisQualifiedforit",
    type: "text",
    id: "DiscussIfTheRegionisQualifiedforit",
    label: "Discuss if the Region is Qualified for it",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter region if the region is qualified",
      },
    ],
  },
  {
    key: "ActionablesDecided",
    type: "text",
    id: "ActionablesDecided",
    label: "Actionables Decided",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter actionables",
      },
    ],
  },
  {
    key: "CROIsClaimRequired",
    name: "CROIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CROIsClaimRequired",
    label: "Do you want to claim travel expense?",
    // validators: [
    //   {
    //     name: "required",
    //     value: false,
    //     validationMessage: "",
    //   },
    // ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Complete Activity 3 ( Regional Office meeting ) ---------------------------------

export const CompletedActivityRegionalOfficevisitPlan_RBH = [
  {
    key: "PersonMet",
    type: "text",
    id: "PersonMet",
    label: "Person Met",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter person met details",
      },
      { "name": "minLength", "value": 2, "validationMessage": "Please enter at least 2 letters" },
      { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 characters." }
    ],
  },
  {
    key: "Designation",
    type: "text",
    id: "Designation",
    label: "Designation",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter designation",
      },
      { "name": "minLength", "value": 2, "validationMessage": "Please enter at least 2 letters" },
      { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 characters." }
    ],
  },
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "DiscussCurrentCampaign",
    type: "text",
    id: "DiscussCurrentCampaign",
    label: "Discuss Current Campaign",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter current campaign",
      },
    ],
  },
  {
    key: "DiscussIfTheRegionisQualifiedforit",
    type: "text",
    id: "DiscussIfTheRegionisQualifiedforit",
    label: "Discuss if the Region is Qualified for it",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter region if the region is qualified",
      },
    ],
  },
  {
    key: "ActionablesDecided",
    type: "text",
    id: "ActionablesDecided",
    label: "Actionables Decided",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter actionables",
      },
    ],
  },
  {
    key: "CROIsClaimRequired",
    name: "CROIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CROIsClaimRequired",
    label: "Do you want to claim travel expense?",
    // validators: [
    //   {
    //     name: "required",
    //     value: false,
    //     validationMessage: "",
    //   },
    // ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];


//------------------- Complete Activity 4 ( Sales Training plan ) ---------------------------------

export const CompletedActivitySalesTrainingplan = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Training",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of training",
      },
    ],
    options: [],
  },
  {
    key: "ISCompleted",
    type: "select",
    id: "ISCompleted",
    label: "Completed",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select an option",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      { key: "Yes", label: "Yes", value: 1 },
      { key: "No", label: "No", value: 0 },
    ],
  },
  {
    key: "Comments",
    type: "text",
    id: "Comments",
    label: "Comments",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter comments",
      }
    ],
  },
];

//------------------- Complete Activity 5 ( joint call bdm ) ---------------------------------

export const CompletedActivityjointcallbdm = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Joint Call",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of joint call",
      },
    ],
    options: [],
  },
  {
    key: "CustomerName",
    type: "text",
    id: "CustomerName",
    label: "Customer Name",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the name of Customer",
      },
    ],
  },
  {
    key: "PremiumAmount",
    type: "number",
    id: "PremiumAmount",
    label: "Premium Amount",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the Premium Amount",
      },
    ],
  },
  {
    key: "ISClosed",
    type: "select",
    id: "ISClosed",
    label: "Closed",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select an option",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      { key: "Yes", label: "Yes", value: 1 },
      { key: "No", label: "No", value: 0 },
    ],
  },
  {
    key: "CJIsClaimRequired",
    name: "CJIsClaimRequired",
    type: "CustomSwitch",
    id: "CJIsClaimRequired",
    label: "Do you want to claim travel expense?",
    // validators: [
    //   {
    //     name: "required",
    //     value: false,
    //     validationMessage: "Please switch claim travel expense",
    //   },
    // ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

export const PolicyApplicationNumber = {
  key: "PolicyApplicationNumber",
  type: "text",
  id: "PolicyApplicationNumber",
  label: "Policy Application Number",
  validators: [
    {
      name: "required",
      value: true,
      validationMessage: "Please enter the Policy Application Number",
    },
  ],
}
export const PolicyApplicationNumberRBH = {
  key: "PolicyApplicationNumber",
  type: "text",
  id: "PolicyApplicationNumber",
  label: "Application Number",
  validators: [
    {
      name: "required",
      value: true,
      validationMessage: "Please enter the Application Number",
    },
    { "name": "minLength", "value": 14, "validationMessage": "Please enter at least 14 digit application number" },
    { "name": "maxLength", "value": 14, "validationMessage": "Please enter no more than 14 characters." }
  ],
}


//------------------- Complete Activity 6 ( Circular for sales enablement ) ---------------------------------

export const CompletedActivityCircularforSalesEnablement = [
  {
    key: "CircularTitle",
    type: "text",
    id: "CircularTitle",
    label: "Circular Title",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the Circular Title",
      },
    ],
  },
  {
    key: "UploadPdfFile",
    type: "file",
    id: "UploadPdfFile",
    label: "Details of Circular",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please upload file",
      },
    ],
  },


  {
    key: "CircularClosed",
    type: "text",
    id: "CircularClosed",
    label: "Closed?",

    validators: [],
  },
];
/*  --------------------------------------------------------
                  Completed Activity end
          --------------------------------------------------------  */


export const Completed_SCHEDULE_STRUCTURED_REVIEW_ABH = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    "value": "New User ? Register here",
     "type": "link",
     "href":"http://localhost:3000/test/",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
  },
  {
    key: "ActionPlanasAgreedWithBDM",
    type: "textarea",
    id: "ActionPlanasAgreedWithBDM",
    label: "Action plan as agreed with BDM",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter action Plan",
      },
    ],
  },
]

export const Completed_BRANCH_VISIT_WITH_BDM_AND_ABH = [
    {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Branch Visit",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of branch visit",
      },
    ],
    options: [],
  },
  {
    key: "MetBranchManager",
    type: "select",
    id: "MetBranchManager",
    label: "Met Branch Manager",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select an option ",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      {
        key: "Yes",
        label: "Yes",
        value: 1,
      },
      { key: "No", label: "No", value: 0 },
    ],
  },
  {
    key: "Comments",
    type: "text",
    id: "Comments",
    label: "Comments",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter comments",
      },
    ],
  },
]

export const Completed_STRUCTURED_ZONAL_HEAD_MEETING = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    "value": "New User ? Register here",
     "type": "link",
     "href":"http://localhost:3000/test/",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
  },
  {
    key: "DiscussCurrentCampaign",
    type: "text",
    id: "DiscussCurrentCampaign",
    label: "Discuss Current Campaign",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter current campaign",
      },
    ],
  },
  {
    key: "DiscussIfTheRegionisQualifiedforit",
    type: "text",
    id: "DiscussIfTheRegionisQualifiedforit",
    label: "Discuss if the Region is Qualified for it",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter region if the region is qualified",
      },
    ],
  },
  {
    key: "ActionablesDecided",
    type: "text",
    id: "ActionablesDecided",
    label: "Actionables Decided",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter actionables",
      },
    ],
  },
]

export const Completed_JOINT_CALLS_WITH_CUSTOMER = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Joint Call",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of joint call",
      },
    ],
    options: [],
  },
  {
    key: "CustomerName",
    type: "text",
    id: "CustomerName",
    label: "Customer Name",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the name of Customer",
      },
    ],
  },
  {
    key: "PremiumAmount",
    type: "number",
    id: "PremiumAmount",
    label: "Premium Amount",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the Premium Amount",
      },
    ],
  },
  {
    key: "ISClosed",
    type: "select",
    id: "ISClosed",
    label: "Closed",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select an option",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      { key: "Yes", label: "Yes", value: 1 },
      { key: "No", label: "No", value: 0 },
    ],
  },
]

export const Completed_REGION_VISIT_WITH_ABH = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Visit",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of joint call",
      },
    ],
    options: [],
  },
  {
    key: "CustomerName",
    type: "text",
    id: "CustomerName",
    label: "Agenda Discussed",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the name of Customer",
      },
    ],
  },
]

export const Completed_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM = [
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Duration of Meeting",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select duration of joint call",
      },
    ],
    options: [],
  },
  {
    key: "CustomerName",
    type: "text",
    id: "CustomerName",
    label: "Comments",

    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter the name of Customer",
      },
    ],
  },
]

export const CompletedActivityScheduleStructureReview_BDM = [
  {
    key: "LeadsGenerated",
    type: "text",
    id: "LeadsGenerated",
    label: "Lead Generated Per Day",
    disabled:true,
    value:"5"
  },
  {
    key: "LeadsConversionPercentage",
    type: "text",
    id: "LeadsConversionPercentage",
    label: "Percentage Lead Conversion",
    disabled:true,
    value:"20%"
  },
  {
    key: "BranchesVisited",
    type: "text",
    id: "BranchesVisited",
    label: "Branch Visits Done",
    disabled:true,
    value:"5"
  },
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "ActionPlanasAgreedWithBDM",
    type: "text",
    id: "ActionPlanasAgreedWithBDM",
    label: "Action plan as agreed with ABH",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter action Plan",
      },
    ],
  },
];

export const CompletedActivityScheduleStructureReview_ABH = [
  {
    key: "BDMWithTwoLeadsPercentage",
    type: "text",
    id: "BDMWithTwoLeadsPercentage",
    label: "%BDM with 2 Leads per day",
    disabled:true,
    value:"5"
  },
  {
    key: "LeadsConversionPercentage",
    type: "text",
    id: "LeadsConversionPercentage",
    label: "% Leads Conversion",
    disabled:true,
    value:"5"
  },
  {
    key: "JointBranchVisits",
    type: "text",
    id: "JointBranchVisits",
    label: "No of Joint Branch Visits with BDM",
    disabled:true,
    value:"5"
  },
  {
    key: "BranchesVisited",
    type: "text",
    id: "BranchesVisited",
    label: "No of Branches Visited",
    disabled:true,
    value:"5"
  },
  {
    key: "RegionVisited",
    type: "text",
    id: "RegionVisited",
    label: "No of regions Visited",
    disabled:true,
    value:"5"
  },
  {
    key: "ActivityDurationID",
    type: "select",
    id: "ActivityDurationID",
    label: "Review Duration",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select review duration",
      },
    ],
    options: [],
  },
  {
    key: "ActionPlanasAgreedWithBDM",
    type: "text",
    id: "ActionPlanasAgreedWithBDM",
    label: "Action plan as agreed with BDM",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please enter action Plan",
      },
    ],
  },
];