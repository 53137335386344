/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-24 14:20:48
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar, getSubordinateList } from "../../../../redux/actions";
import { customValidators, persistancyLeadFilterForm,PERSISTANCY_BANCA_LEAD_DATE } from "./persistancyLeadFilterForm";
import { ROLE_ABH, ROLE_BDM, ROLE_FPM, USER_TYPE } from "../../../../utils/config";
import { getDropdownDataBranch } from '../../../../utils/utils';
import { useHistory } from "react-router-dom";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down('xs')]: {
      display: "block"
    },
    [theme.breakpoints.down('sm')]: {
      display: "block"
    }
  },
  desktopFilter: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  }
}));


const PersistancyLeadFilter = ({ getFilteredLeads, getAllLeads, parent, handleClose, filterState, setFilterState, setFilterDisabled, setSelectedFilter, anchor }) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
    } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;

  const {
    leadStatus,
    getCampaignNames
  } = useSelector((next) => next.leadFormReducer); // Activities,
  const userState = useSelector(mainState => mainState.userReducer);
  const { persistancyActivity } = useSelector((nextState) => nextState.persistancyLeadListReducer);
  const UserId = userState?.profileInfo?.userId
  const FullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const [UserFullName, setUserFullName] = useState(FullName);
  const history = useHistory();
  const roleId = userState?.profileInfo?.roleId;
  const channelId = userState?.profileInfo?.channelid

  const { ProductDetails,Branches } = useSelector((nextReducer) => nextReducer.commonReducer)
  const { GetBDMByABH } = useSelector((reducer) => reducer.commonReducer)
  
  const [newUserList, setNewUserList] = useState([{
    key: UserId,
    label: UserFullName,
    value: UserId
  }])
  const [state, setState] = useState({
    current: { id: 1 },
    formData: persistancyLeadFilterForm,
    autoCompleteItems: {
      product: [],
      activityID: [],
      BranchId: []
    },
  });

  const dynamicRef = React.useRef();

  const meetingDateFormate = "YYYY-MM-DD";

  useEffect(() => {
    if (roleId === ROLE_ABH) {
      persistancyLeadFilterForm[0].hide = false
    }
  }, [roleId])
  useEffect(() => {
    if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
      getSub()
    }
    setFilterState({
      UserName: UserFullName
    })
  }, [UserFullName]);

  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { "id": `${option[id]}`, "label": `${option[label]}` }
      })
    }
    arr.shift({ "id": " ", "label": "select" })
    arr.unshift({ "id": " ", "label": "select" })
    return arr;
  }

  useEffect(() => {
    const autoCompleteItems = { ...state.autoCompleteItems };
    autoCompleteItems.product = getDropdownOFData(ProductDetails, "key", "label");
    autoCompleteItems.activityID = getDropdownData(
      persistancyActivity,
      "ActivityID",
      "ActivityName",
      "ActivityID"
    );

    if(
       channelId == 1 && roleId == ROLE_ABH ||
       channelId == 1 && roleId == ROLE_BDM ||
       channelId == 3 && roleId == ROLE_ABH ||
       channelId == 3 && roleId == ROLE_BDM
      ){
        persistancyLeadFilterForm[15].hide = false;
        persistancyLeadFilterForm[16].hide = false;
      }
      else {
      persistancyLeadFilterForm[15].hide = true;
      persistancyLeadFilterForm[16].hide = true;
      }
    persistancyLeadFilterForm[17].hide = true;
    persistancyLeadFilterForm[18].hide = true;
    const BranchesCopy = [...Branches];
    autoCompleteItems.BranchId = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
    const formData = initFormData(state.formData);
    setState({ formData: formData, autoCompleteItems: autoCompleteItems });

  }, []);

  const getSub = async () => {
    const formData = [...persistancyLeadFilterForm]
    dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
      const r = response.data.map(el => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID
        }
      });
      setNewUserList([...newUserList, ...r])
      formData[1].options = [...newUserList, ...r];

      if (GetBDMByABH !== 'all') {
        const res = response.data.filter((el) => {
          if (GetBDMByABH == el.userID)
            return true;
        });
        setUserFullName(res[0].userName)
        formData[0].hide = true;
        formData[1].hide = true;
        formData[2].hide = false;
      }
    })
  }
  const onSubmitHandler = (model) => {
    if (model.LeadName === "") {
      model.LeadName = null
    }
    if (model.CreatedDateTime === 0) {
      model.CreatedDateTime = null
    }
    if (model.MeetingDate === 0) {
      model.MeetingDate = null
    }
    if (model.DueDateTime === 0) {
      model.DueDateTime = null
    }
    if (model.MobileNo === "") {
      model.MobileNo = null
    }


    if (model.teamMember === 0 || model.teamMember === undefined) {
      model.teamMember = null
    }
    const status = model.persistancycurrentStatus?.map((val) => {
      return val.value;
    })
    model.currentStatus = status?.join(',');

    if (model.currentStatus === "") {
      model.currentStatus = null
    }
    const { CreatedDateEndTIme, LeadName, CreatedDateTime, CreatedDateStartTIme, MeetingOn, currentStatus, product, activityID, MeetingDate, fromdate, todate, MobileNo, teamMember,DueStartDate,DueEndDate ,DueDateTime,BranchId} = model
    const persistancyActivityValue = [...persistancyActivity].some((el) => el.ActivityID === activityID)
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    let duestart=null
    let dueend=null
    if (MeetingDate && MeetingDate !== null && MeetingDate !== 'duration' && MeetingDate !== undefined) {
      const lastdatearr = model.MeetingDate.split(',');
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (CreatedDateTime && CreatedDateTime !== null && CreatedDateTime !== 'duration' && CreatedDateTime !== undefined) {
      const lastdatearr = model.CreatedDateTime.split(',');
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (DueDateTime && DueDateTime !== null && DueDateTime !== 'duration' && DueDateTime !== undefined) {
      const lastdatearr = model.DueDateTime.split(',');
      duestart = lastdatearr[0] ? lastdatearr[0] : null;
      dueend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (MeetingDate && MeetingDate === 'duration') {
      if (fromdate === null || todate === null) {
        dispatch(activateErrorMessageBar('Please Select Value For Both Meeting Start Date and End Date'))
        return false
      }
      meetingstart = model.fromdate;
      meetingend = model.todate
    }
    if (CreatedDateTime && CreatedDateTime === 'duration') {
      if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
        dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
        return false
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme
    }
    if (DueDateTime && DueDateTime === 'duration') {
      if (DueStartDate === null || DueEndDate === null) {
        dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
        return false
      }
      duestart = model.DueStartDate;
      dueend = model.DueEndDate
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;

    model.DueStartDate = duestart;
    model.DueEndDate = dueend;
    if (CreatedDateTime !== null || LeadName !== null || product !== 0 || MeetingOn !== null || currentStatus !== null ||
      activityID !== 0 || MeetingDate !== null || MobileNo !== null || DueStartDate !== null || DueEndDate !== null ||
      fromdate !== null || todate !== null || teamMember !== null || BranchId !==0) {
      setFilterState({
        ...filterState,
        LeadName: LeadName,
        CreatedDateTime: CreatedDateTime,
        CreatedDateStartTIme: CreatedDateStartTIme,
        CreatedDateEndTIme: CreatedDateEndTIme,
        MeetingOn: null,
        LastUpdateDateTime: null,
        currentStatus: currentStatus,
        product: product,
        activityID: activityID,
        MeetingDate: MeetingDate,
        fromdate: fromdate,
        todate: todate,
        MobileNo: MobileNo,
        DueStartDate:DueStartDate,
        DueEndDate:DueEndDate,
        BranchId:BranchId,
        "IsBacklog": false,
      })
      getFilteredLeads(model, teamMember, persistancyActivityValue)
      handleClose();
    } else {
      dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
    }
  };

  const onResetHandler = () => {

    setFilterState({
      ...filterState,
      LeadName: "",
      CreatedDateTime: 0,
      CreatedDateStartTIme: null,
      CreatedDateEndTIme: null,
      MeetingOn: null,
      LastUpdateDateTime: null,
      currentStatus: "",
      persistancycurrentStatus: [],
      product: "",
      productlabel: "",
      activityIDlabel: "",
      MeetingDate: 0,
      fromdate: null,
      todate: null,
      campaign: "null",
      MobileNo: "",
      userType: 0,
      DueDateTime:0,
      DueStartDate:null,
      DueEndDate:null,
      "IsBacklog": false,
      teamMember: 0,
      BranchId:'',
      BranchIdlabel:''
    })
    persistancyLeadFilterForm[1].hide = true
    persistancyLeadFilterForm[2].hide = false
    persistancyLeadFilterForm[5].hide = true
    persistancyLeadFilterForm[6].hide = true
    persistancyLeadFilterForm[9].hide = true
    persistancyLeadFilterForm[10].hide = true
    persistancyLeadFilterForm[17].hide = true
    persistancyLeadFilterForm[18].hide = true
    getAllLeads()
    setFilterDisabled();
    handleClose();
  };
  const onChangeHandler = (e, key) => {
    // console.log(ProductDetails)
    // const branchNameValue = [...ProductDetails].some((el) => el.key === e.target.value)

    if (key === 'LeadName') {
      setFilterState({
        ...filterState,
        LeadName: e.target.value
      })
    }
    if (key === 'MobileNumber') {
      setFilterState({
        ...filterState,
        MobileNo: e.target.value
      })
    }
    if (key === 'CreatedDateTime') {
      setFilterState({
        ...filterState,
        CreatedDateTime: e.target.value
      })
      if (e.target.value === 'duration') {
        persistancyLeadFilterForm[5].hide = false;
        persistancyLeadFilterForm[6].hide = false;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
          CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
        });
      } else {
        persistancyLeadFilterForm[5].hide = true;
        persistancyLeadFilterForm[6].hide = true;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: "null",
          CreatedDateEndTIme: "null",
        });
      }
    }
    if (key === 'currentStatus') {
      setFilterState({
        ...filterState,
        currentStatus: e.target.value
      })
    }
    
    if (key === 'LastUpdateDateTime') {
      setFilterState({
        ...filterState,
        LastUpdateDateTime: e.target.value
      })
    }
    if (key === 'product') {
      const branchNameValue = ProductDetails.filter((el) => el.key === parseInt(e.target.value))
      setFilterState({
        ...filterState,
        product: e.target.value,
        productlabel: branchNameValue[0]?.label
      })
    }
    // if (key === 'MeetingDate') {
    //   setFilterState({
    //     ...filterState,
    //     MeetingDate: e.target.value
    //   })
    //   if (e.target.value === 'duration') {
    //     persistancyLeadFilterForm[9].hide = false;
    //     persistancyLeadFilterForm[10].hide = false;
    //   } else {
    //     persistancyLeadFilterForm[9].hide = true;
    //     persistancyLeadFilterForm[10].hide = true;
    //   }
    // }
    if (key === 'MeetingDate') {
      setFilterState({
        ...filterState,
        MeetingDate: e.target.value
      })
      if (e.target.value === 'duration') {
        persistancyLeadFilterForm[9].hide = false;
        persistancyLeadFilterForm[10].hide = false;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: moment(new Date()).format(meetingDateFormate),
          todate: moment(new Date()).format(meetingDateFormate),
        })
      } else {
        persistancyLeadFilterForm[9].hide = true;
        persistancyLeadFilterForm[10].hide = true;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: "null",
          todate: "null",
        })
      }
    }

    if (key === 'DueDateTime') {
      setFilterState({
        ...filterState,
        DueDateTime: e.target.value
      })
      if (e.target.value === 'duration') {
        persistancyLeadFilterForm[17].hide = false;
        persistancyLeadFilterForm[18].hide = false;
        setFilterState({
          ...filterState,
          DueDateTime: e.target.value,
          DueStartDate: moment(new Date()).format(meetingDateFormate),
          DueEndDate: moment(new Date()).format(meetingDateFormate),
        })
      } else {
        persistancyLeadFilterForm[17].hide = true;
        persistancyLeadFilterForm[18].hide = true;
        setFilterState({
          ...filterState,
          DueDateTime: e.target.value,
          DueStartDate: "null",
          DueEndDate: "null",
        })
      }
    }

    if (key === 'CreatedDateStartTIme') {
      persistancyLeadFilterForm[6].min = e.target.value
    }
    if (key === 'fromdate') {
      persistancyLeadFilterForm[10].min = e.target.value
    }
    if (key === 'fromdate') {
      setFilterState({
        ...filterState,
        fromdate: e.target.value
      })
    }
    if (key === 'todate') {
      setFilterState({
        ...filterState,
        todate: e.target.value
      })
    }
    if (key === 'DueStartDate') {
      persistancyLeadFilterForm[18].min = e.target.value
    }
    if (key === 'DueStartDate') {
      setFilterState({
        ...filterState,
        DueStartDate: e.target.value
      })
    }
    if (key === 'DueEndDate') {
      setFilterState({
        ...filterState,
        DueEndDate: e.target.value
      })
    }
    if (key === "userType") {
      const getUserType = e.target.value
      if (getUserType === "team") {
        persistancyLeadFilterForm[1].hide = false
        persistancyLeadFilterForm[2].hide = true
        // dispatch({
        //   type: "viewFor",
        //   payload: ""
        // })
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "BDM"
        })
      } else {
        persistancyLeadFilterForm[1].hide = true
        persistancyLeadFilterForm[2].hide = false
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "SELF"
        })
      }
    }
  };
  let persistancy_status;
  if(channelId == 1 && history?.location?.pathname === "/app/persistencylead/all"){
     persistancy_status = [26,27];
  }else{
    persistancy_status = [1, 2, 3, 4, 5, 7, 13, 14,25];
  }
  
  const getDropdownData = (optionsArr, key, label, value) => {
   
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let filter_arr = [];
  if (leadStatus && leadStatus.length > 0) {
  
    filter_arr = leadStatus.filter((option) => {
      if (persistancy_status.includes(option.LeadStatusId)) {
        return option;
      }
    });
  }
  let LEAD_STATUS = []


  if (channelId == 1 && roleId == "ABH") {
    
    const restrictedArr = ["CONVERTED", "Auto Closed"]
    LEAD_STATUS = getDropdownData(

      filter_arr?.filter((option) => option.LeadStatus !== 'Converted'),
      "LeadStatus",
      "LeadStatus",
      "LeadStatusId"
    );
    LEAD_STATUS = LEAD_STATUS?.filter((item) => !restrictedArr.includes(item.key))
  } else {

    const restrictedArr = ["CONVERTED"]
    LEAD_STATUS = getDropdownData(

      filter_arr?.filter((option) => option.LeadStatus !== 'Converted'),
      "LeadStatus",
      "LeadStatus",
      "LeadStatusId"
    );
    LEAD_STATUS = LEAD_STATUS?.filter((item) => !restrictedArr.includes(item.key))

  }


  const CAMPAIGN_NAMES = getDropdownData(
    getCampaignNames,
    "CampaignNameID",
    "CampaignName",
    "CampaignNameID"
  )

  const onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "activityID") {
      setFilterState({
        activityID: item.value
      })
    } else if (key === "BranchId") {
      setFilterState({
        BranchId: item.value
      })
    }
  }

  const initFormData = (formFields) => {
    const formData = [...formFields];
    const currenstatusoption = LEAD_STATUS
    formData[0].options = USER_TYPE
    formData[2].disabled = true;
    setFilterState({ ...filterState, UserName: userName });
    formData[12].options = ProductDetails;
    formData[7].options = currenstatusoption;
    // formData[11].options = persistancy_Activity;
    formData[13].options = CAMPAIGN_NAMES;
    if(channelId == 1 && history?.location?.pathname === "/app/persistencylead/all"){
      formData[8].hide = true;
      formData[16].options =PERSISTANCY_BANCA_LEAD_DATE
    }
    return formData;
  };
  const classes = useStyles();
  return (
    <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          {" "}
          <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            ref={dynamicRef}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={filterState}
            customValidators={customValidators}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key) => onChangeHandler(event, key)}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={state.autoCompleteItems}
            showAstersikOnRequiredFields={false}

            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PersistancyLeadFilter);
